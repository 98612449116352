import { lazy } from 'react';
import { authRoles } from '../../../../auth';

const MailSendedPage = lazy(() => import('./MailSendedPage'));

const MailSendedPageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'mail-sended',
      element: <MailSendedPage />,
    },
  ],
};

export default MailSendedPageConfig;
