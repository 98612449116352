import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { DataStore } from 'aws-amplify';
import _ from 'lodash';

import { Request } from '../../../models';
import {
  insertDatastoreRequest,
  updateDatastoreRequest,
  deleteDatastoreRequest,
} from '../../main/apps/requests/store/requestSlice';
import { authRoles } from '../../auth';

const RequestObserve = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(({ auth }) => auth.user);

  let isCurrentUserAuthenticated = false;
  let isFRUser = false;

  if (!_.isEmpty(authUser.role)) {
    isCurrentUserAuthenticated = _.has(authUser, 'uid');
    isFRUser = _.isEmpty(authUser.role.filter((e) => authRoles.authorized.includes(e)));
  }

  useEffect(() => {
    if (isCurrentUserAuthenticated && !isFRUser) {
      const subscription = DataStore.observe(Request).subscribe((msg) => {
        const handler = {
          insertDatastoreRequest,
          updateDatastoreRequest,
          deleteDatastoreRequest,
        };

        dispatch(
          handler[`${_.toLower(msg.opType)}DatastoreRequest`]({
            data: msg.element,
          })
        );
      });

      return () => subscription.unsubscribe();
    }
  }, [dispatch, isCurrentUserAuthenticated, isFRUser]);
};

export default RequestObserve;
