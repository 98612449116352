const locale = {
  requestsText: 'Request List',
  searchText: 'Search',
  sendMail: 'Send Mail',
  mailSent: 'Mail Sent',
  requestListTableColumnNameId: 'Id',
  requestListTableColumnNameCreatedAt: 'Created Date',
  requestListTableColumnNameUpdatedAt: 'Updated Date',
  requestListTableColumnNameSourceStatus: 'Source Status',
  requestListTableColumnNameStatus: 'Status',
  requestListTableHeaderFileUploadPercentage: '{{percentage}} %',
  UPLOAD: 'Upload',
  MANUEL: 'Manuel',
  PENDING: 'Pending',
  ERROR: 'Error',
  VALID: 'Valid',
  COMPLETED: 'Completed',
  EMAIL_SENDED: 'Mail Sended',
  PARTNER_PENDING: 'Pending at Partner',
  PARTNER_ERROR: 'Partner Error',
  PARTNER_VALID: 'Partner Completed',
  PARTNER_APPROVED: 'Partner Approved',
  COLUMN_NULL_ERROR: 'Column null',
  COLUMN_PRODUCT_NOT_FOUND: 'Column not found',
  CELL_PRODUCT_NOT_FOUND: 'Column not found',
  PSB: 'Performance Support Bonus',
  CELL_NULL_ERROR: '{{code}} not found',
  STC: 'Store Code',
  COLUMN_NAME_ID: 'System Id',
  COLUMN_NAME_VIEW_ID: 'Id',
  COLUMN_NAME_STC: 'Store Code',
  COLUMN_NAME_STN: 'Store Name',
  COLUMN_NAME_TYP: 'Type',
  COLUMN_NAME_TOT: 'Total Amount',
  COLUMN_NAME_TOV: 'Includet VAT',
  COLUMN_NAME_PSB: 'Performance Support Bonus',
  COLUMN_NAME_RCO: 'Revenue Commission',
  COLUMN_NAME_PPO: 'Payment Per Order',
  COLUMN_NAME_TIP: 'Tip',
  COLUMN_NAME_SSB: 'Startup Support Bonus',
  COLUMN_NAME_CREATED_DATE: 'Created Date',
  COLUMN_NAME_UPDATED_DATE: 'Updated Date',
  COLUMN_NAME_ERROR_LIST: 'Error List',
  COLUMN_NAME_STATUS: 'Status',
  COLUMN_NAME_SENDMAIL: '',
  COLUMN_NAME_DOWNLOAD: '',
  COLUMN_NAME_SYSTEM_INDEX: 'Index',
  BACK_REQUESTS_NAME: 'Back Request List',
  REQUESTS_DETAIL_NAME: 'Requests Detail',
  CURRENT_LANGUAGE: 'enUS',
  MESSAGE_UPLOAD_FILE_SUCCESS: 'File uploaded successfully',
  MESSAGE_UPLOAD_FILE_ERROR: 'Unexpected error while uploading',
  MESSAGE_SEND_MAIL_SUCCESS: 'Mail sending process started.',
  MESSAGE_SEND_MAIL_ERROR: 'Unexpected error while sending mail',
  MESSAGE_REMOVE_REQUEST_LIST: 'Request list deleted successfully.',
  momentLang: 'en',
  Delete: 'Delete',
  Cancel: 'Cancel',
  Download: 'Download',
  Agree: 'Agree',
  SendMail: 'Send Mail',
  formatDate: '{{date, dd/MM/yyyy}}',
  formatCurrency: '{{value, price, USD}}',
  NO_ROWS: 'No Requests Data',
  SEND_TO_APPROVAL: 'Send to Approval',
  MESSAGE_SEND_MAIL_DIALOG_TITLE: 'Send Mail',
  MESSAGE_SEND_MAIL_DIALOG_CONTENT: 'The request lists will be sended mail, do you agree?',
  MESSAGE_CANT_DELETE_REQUEST_LIST_ERROR:
    'There is a request in the request list whose status is not eligible for deletion.',
  REQUEST_HEADER: 'Requests',
  REQUEST_HEADER_FAB_ACTION_ADD: 'Add',
  REQUEST_HEADER_FAB_ACTION_DELETE: 'Delete',
  REQUEST_HEADER_FAB_ACTION_EXPORT: 'Export',
  REQUEST_MESSAGE_DELETE_REQUEST_LIST_DIALOG: 'All requests remove, do you agree?',
  NO_REQUEST_MESSAGE: 'There is no such request',
  NO_REQUEST_LINK: 'Go to Requests Page',
  DELETE_REQUEST_ERROR_MESSAGE: 'Completed requests cannot be deleted',
  PROCESS: 'Confirm',
  MESSAGE_DELETE_REQUEST_DIALOG: 'The requests below will be deleted, do you agree?',
  MESSAGE_SEND_REQUEST_DIALOG:
    'Sending request {{viewId}} to warehouse {{SC}} for approval. Do you confirm?',
  MESSAGE_SEND_REQUEST_LIST: 'The following requests have been submitted for approval',
  PAGINATION_PREV: 'Prev',
  PAGINATION_NEXT: 'Next',
  SERVER_STATUS_CONNECTED: 'Online',
  SERVER_STATUS_DISCONNECTED: 'Offline',

  MESSAGE_ADD_REQUEST_LIST: '{{viewId}} request list added',
  MESSAGE_UPDATE_REQUEST_LIST: '{{viewId}} request list updated',
  MESSAGE_DELETE_REQUEST_LIST: '{{viewId}} request list deleted',
  MESSAGE_DELETE_REQUEST_LIST_DIALOG: 'The request lists below will be deleted, do you agree?',
  REQUESTS_NO_ROWS: 'No request list data',
  MESSAGE_ADD_REQUEST: '{{viewId}} request added',
  MESSAGE_UPDATE_REQUEST: '{{viewId}} request updated',
  MESSAGE_DELETE_REQUEST: '{{viewId}} request deleted',
  NO_OPTION: 'No option',
};

export default locale;
