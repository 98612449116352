import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { DataStore } from 'aws-amplify';

import { RequestList } from '../../../models';
import {
  deleteRequests,
  insertRequests,
  updateRequests,
} from '../../main/apps/requests/store/requestsSlice';

const RequestsObserve = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(({ auth }) => auth.user);

  let isCurrentUserAuthenticated = false;

  if (!_.isEmpty(authUser.role)) {
    isCurrentUserAuthenticated = _.has(authUser, 'uid');
  }

  useEffect(() => {
    if (isCurrentUserAuthenticated) {
      const subscription = DataStore.observe(RequestList).subscribe((msg) => {
        const handler = {
          insertRequests,
          updateRequests,
          deleteRequests,
        };

        dispatch(
          handler[`${_.toLower(msg.opType)}Requests`]({
            data: msg.element,
          })
        );
      });

      return () => subscription.unsubscribe();
    }
  }, [dispatch, isCurrentUserAuthenticated]);
};

export default RequestsObserve;
