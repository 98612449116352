import ContactsAppConfig from './contacts/ContactsAppConfig';
import FranchiseAppConfig from './franchise/FranchiseAppConfig';
import RequestsAppConfig from './requests/RequestsAppConfig';
import ProductsAppConfig from './product/ProductsAppConfig';
import TagAppConfig from './tag/TagAppConfig';

const appsConfigs = [
  FranchiseAppConfig,
  ContactsAppConfig,
  RequestsAppConfig,
  ProductsAppConfig,
  TagAppConfig,
];

export default appsConfigs;
