import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { API, graphqlOperation } from 'aws-amplify';
import i18next from 'i18next';
import _ from 'lodash';

import * as subscriptions from '../graphql/subscriptions';
import en from '../i18n/en';
import tr from '../i18n/tr';
import { authRoles } from '../../auth';
import { updateNotificationRequest } from '../../main/apps/requests/store/requestSlice';

i18next.addResourceBundle('en', 'RequestsNotification', en);
i18next.addResourceBundle('tr', 'RequestsNotification', tr);

const RequestSubscriptions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('RequestsNotification');
  const authUser = useSelector(({ auth }) => auth.user);
  const isVisibleNotificationFRUser = (status) => {
    return _.includes(['PARTNER_APPROVED', 'COMPLETED'], status);
  };

  let isCurrentUserAuthenticated = false;
  let isFRUser = false;

  if (!_.isEmpty(authUser.role)) {
    isCurrentUserAuthenticated = _.has(authUser, 'uid');
    isFRUser = _.isEmpty(authUser.role.filter((e) => authRoles.authorized.includes(e)));
  }

  useEffect(() => {
    if (isCurrentUserAuthenticated && !isFRUser) {
      const updateSubscription = API.graphql(
        graphqlOperation(subscriptions.onUpdateRequest)
      ).subscribe({
        next: ({ value }) => {
          const {
            data: {
              onUpdateRequest: { viewId, status, mailSendUserId },
            },
          } = value;

          const isNotificationVisible = isVisibleNotificationFRUser(status);
          if (isNotificationVisible) {
            // TODO: mailSendUserId den depo adiyla notification gonderilecek, EX: Banabi Ankara depo talebi tamamlandi, EX: Banabi Ankara depo talebi LOGO ya aktarildi (Kaan)
            dispatch(
              updateNotificationRequest({
                message: t(`${status}_NOTIFICATION_MESSAGE_UPDATE_REQUEST`, { viewId }),
                options: { variant: 'success' },
              })
            );
          }
        },
        error: (error) => {
          console.warn(error);
        },
      });

      return () => updateSubscription.unsubscribe();
    }
  }, [dispatch, isCurrentUserAuthenticated, isFRUser, t]);
};

export default RequestSubscriptions;
