import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { API, Auth, DataStore } from 'aws-amplify';
import _ from 'lodash';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';

import { addNotification } from '../../../../fuse-layouts/shared-components/notificationPanel/store/dataSlice';
import NotificationModel from '../../../../fuse-layouts/shared-components/notificationPanel/model/NotificationModel';
import { setDataGridLoading } from './requestsSlice';
import { Request, RequestList } from '../../../../../models';
import PrepareRow from '../request/components/PrepareRow';
import PrepareStatus from '../request/components/PrepareStatus';

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
export const getRequest = createAsyncThunk(
  'requestApp/request/getRequest',
  async (params, { dispatch, getState }) => {
    const {
      routeParams: { requestListId },
      t,
    } = params;

    dispatch(setRequestDataGridLoading(true));
    const requestList = await DataStore.query(RequestList, requestListId);
    const requests = (await DataStore.query(Request)).filter(
      (c) => c.requestList.id === requestList.id
    );

    return {
      requestList,
      requests,
      t,
    };
  }
);
export const removeRequest = createAsyncThunk(
  'requestApp/request/removeRequest',
  async (params, { dispatch, getState }) => {
    dispatch(setRequestDataGridLoading(true));
    const { removeRequestIdList } = params;
    const { selectionModel, requestList } = getState().requestApp.request;
    const { id, status } = requestList;

    const deletedRequestListModel = !_.isEmpty(removeRequestIdList)
      ? removeRequestIdList
      : selectionModel;

    await asyncForEach(deletedRequestListModel, async (deletedRequestListModelItem) => {
      const request = await DataStore.query(Request, deletedRequestListModelItem);
      await DataStore.delete(request);
    });

    const requests = (await DataStore.query(Request)).filter((c) => c.requestList.id === id);
    const finalChangedStatus = PrepareStatus(requests);

    if (!_.eq(finalChangedStatus, status)) {
      await DataStore.save(
        RequestList.copyOf(requestList, (updated) => {
          updated.status = finalChangedStatus;
        })
      );
    }

    return selectionModel;
  }
);

export const sendMailRequest = createAsyncThunk(
  'requestsApp/requests/sendMailRequest',
  async (params, { dispatch, getState }) => {
    const {
      lang,
      uid,
      requestId,
      requestVersion,
      request,
      isRequestList,
      successMessage,
      errorMessage,
    } = params;
    const { requestList } = getState().requestApp.request;
    const requestStatusList = _.map(request, (i) => _.pick(i, 'id', 'status'));

    const data = {
      language: lang,
      requestIdValue: requestList.id,
      requestVersionValue: requestList._version,
      owner: uid,
      requestStatusList,
      isRequestList,
    };
    if (!isRequestList) {
      _.assignIn(data, {
        requestIdValue: requestId,
        requestVersionValue: requestVersion,
        requestListId: requestList.id,
        requestListVersion: requestList._version,
      });
    }

    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const requestInfo = {
      body: { data },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await API.post('triggerapi', '/send-email', requestInfo);
    const isResultSuccess = !_.isEmpty(result) && result.isSuccess;

    dispatch(
      addNotification(
        NotificationModel({
          message: isResultSuccess ? successMessage : errorMessage,
          options: { variant: isResultSuccess ? 'success' : 'error' },
        })
      )
    );

    return result;
  }
);

export const updateNotificationRequest = createAsyncThunk(
  'requestApp/request/updateNotificationRequest',
  async (params, { dispatch, getState }) => {
    dispatch(addNotification(NotificationModel(params)));

    return null;
  }
);

export const insertDatastoreRequest = createAsyncThunk(
  'requestApp/request/addDatastoreRequest',
  async (params, { dispatch, getState }) => {
    const { data } = params;

    dispatch(setRequestDataGridLoading(true));

    return data;
  }
);
export const updateDatastoreRequest = createAsyncThunk(
  'requestApp/request/updateDatastoreRequest',
  async (params, { dispatch, getState }) => {
    const { data } = params;

    dispatch(setRequestDataGridLoading(true));

    return data;
  }
);
export const deleteDatastoreRequest = createAsyncThunk(
  'requestApp/request/deleteDatastoreRequest',
  async (params, { dispatch, getState }) => {
    const { data } = params;

    dispatch(setRequestDataGridLoading(true));

    return data;
  }
);

export const insertRequest = createAsyncThunk(
  'requestApp/request/addRequest',
  async (params, { dispatch, getState }) => {
    const { data, t, isNewRow } = params;
    dispatch(setRequestDataGridLoading(true));

    return {
      request: data,
      t,
      isNewRow,
    };
  }
);
export const updateRequest = createAsyncThunk(
  'requestApp/request/updateRequest',
  async (params, { dispatch, getState }) => {
    const { data, t } = params;
    dispatch(setRequestDataGridLoading(true));

    return {
      request: data,
      t,
    };
  }
);
export const deleteRequest = createAsyncThunk(
  'requestApp/request/deleteRequest',
  async (params, { dispatch, getState }) => {
    const { data } = params;
    dispatch(setRequestDataGridLoading(true));

    return data.id;
  }
);

export const deleteRequestList = createAsyncThunk(
  'requestApp/request/requestList/deleteRequests',
  async (params, { dispatch, getState }) => {
    const { data, messageTitle } = params;
    dispatch(setRequestDataGridLoading(true));

    const deleteMessageContentRender = () => {
      return {
        message: (
          <div>
            {messageTitle}
            <List>
              <ListItem key={data.id}>
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary={data.id} />
              </ListItem>
            </List>
          </div>
        ),
        options: { variant: 'success' },
      };
    };
    dispatch(addNotification(NotificationModel(deleteMessageContentRender())));

    return data.id;
  }
);

export const updateRequestList = createAsyncThunk(
  'requestApp/request/requestList/updateRequests',
  async (params, { dispatch, getState }) => {
    const { data, messageTitle } = params;
    dispatch(setDataGridLoading(true));

    const updateMessageContentRender = () => {
      return {
        message: (
          <div>
            {messageTitle}
            <List>
              <ListItem key={data.id}>
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary={data.id} />
              </ListItem>
            </List>
          </div>
        ),
        options: { variant: 'success' },
      };
    };
    dispatch(addNotification(NotificationModel(updateMessageContentRender())));

    return data;
  }
);

const requestAdapter = createEntityAdapter({});

export const { selectAll: selectRequest, selectById: selectRequestById } =
  requestAdapter.getSelectors((state) => state.requestApp.request);

const requestSlice = createSlice({
  name: 'requestApp/request',
  initialState: requestAdapter.getInitialState({
    requestPageLoading: true,
    requestDataGridLoading: false,
    requestList: null,

    noRequest: false,
    requestServerStatusConnection: true,
    sortModel: [
      {
        field: 'updatedDate',
        sort: 'desc',
      },
    ],
    filterModel: undefined,
    selectionModel: [],
    limit: 10,
  }),
  reducers: {
    setRequestPageLoading: {
      reducer: (state, action) => {
        state.requestPageLoading = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
    setRequestDataGridLoading: {
      reducer: (state, action) => {
        state.requestDataGridLoading = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
    resetRequest: () => null,
    setSelectionModel: {
      reducer: (state, action) => {
        state.selectionModel = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
    setSortModel: {
      reducer: (state, action) => {
        state.sortModel = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
    setFilterModel: {
      reducer: (state, action) => {
        state.filterModel = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
  },
  extraReducers: {
    [getRequest.fulfilled]: (state, action) => {
      const { requestList, requests, t } = action.payload;

      state.requestList = requestList;
      const displayRequests = PrepareRow(requests, t);
      state.noRequest = false;
      requestAdapter.setAll(state, displayRequests);
      state.sortModel = [
        {
          field: 'updatedDate',
          sort: 'desc',
        },
      ];
      state.filterModel = undefined;
      state.selectionModel = [];
      state.requestDataGridLoading = false;
      state.requestPageLoading = false;
    },
    [removeRequest.fulfilled]: (state, action) => {
      requestAdapter.updateMany(state, action.payload);
      if (!_.isEmpty(action.payload)) {
        state.requestDataGridLoading = false;
      }
    },
    [insertDatastoreRequest.fulfilled]: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        requestAdapter.removeOne(state, action.payload);
        state.requestDataGridLoading = false;
      }
    },
    [updateDatastoreRequest.fulfilled]: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        requestAdapter.upsertOne(state, action.payload);
        state.requestDataGridLoading = false;
      }
    },
    [deleteDatastoreRequest.fulfilled]: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        requestAdapter.addOne(state, action.payload);
        state.requestDataGridLoading = false;
      }
    },
    [insertRequest.fulfilled]: (state, action) => {
      const { request, t, isNewRow } = action.payload;

      let displayRequests = request;
      if (isNewRow) {
        displayRequests = PrepareRow([request], t);
      }
      requestAdapter.addOne(state, displayRequests[0]);
      state.requestDataGridLoading = false;
    },
    [updateRequest.fulfilled]: (state, action) => {
      const { request, t } = action.payload;

      const displayRequests = PrepareRow([request], t);
      requestAdapter.upsertOne(state, displayRequests[0]);
      state.requestDataGridLoading = false;
    },
    [deleteRequest.fulfilled]: (state, action) => {
      requestAdapter.removeOne(state, action.payload);
      state.requestDataGridLoading = false;
    },
    [deleteRequestList.fulfilled]: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        state.requestList = null;
        state.noRequest = true;
        state.requestDataGridLoading = false;
      }
    },
    [updateRequestList.fulfilled]: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        state.requestList.status = action.payload.status;
        state.requestDataGridLoading = false;
      }
    },
  },
});

export const {
  setRequestPageLoading,
  setRequestDataGridLoading,
  setSelectionModel,
  resetRequest,
  setSortModel,
  setFilterModel,
} = requestSlice.actions;

export default requestSlice.reducer;
