import { createSlice } from '@reduxjs/toolkit';
import cognitoService from 'app/services/cognitoService';

export const sendVerificationCode =
  ({ email }) =>
  async (dispatch) => {
    return cognitoService
      .sendVerificationCode(email)
      .then((response) => {
        return dispatch(resetPasswordSuccess());
      })
      .catch((errors) => {
        return dispatch(resetPasswordError(errors));
      });
  };

export const changePassword =
  ({ email, code, password }) =>
  async (dispatch) => {
    return cognitoService
      .changePassword(email, code, password)
      .then((response) => {
        return dispatch(resetPasswordSuccess());
      })
      .catch((errors) => {
        console.log(errors);
        return dispatch(resetPasswordError(errors));
      });
  };

const initialState = {
  success: false,
  errors: [],
};

const resetPasswordSlice = createSlice({
  name: 'auth/resetPassword',
  initialState,
  reducers: {
    resetPasswordSuccess: (state, action) => {
      state.success = true;
      state.errors = [];
    },
    resetPasswordError: (state, action) => {
      state.success = false;
      state.errors = action.payload;
    },
  },
  extraReducers: {},
});

export const { resetPasswordSuccess, resetPasswordError } = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;

