const locale = {
  LoginToYourAccount: 'Login to your account',
  Welcome: 'Welcome',
  Login: 'Login',
  ToThe: 'to the',
  Email: 'Email',
  Password: 'Password',
  RememberMe: 'Remember Me',
  Masraff: 'Masraff',
  AccountsPayableProcesses: 'Accounts Payable Processes',
  CreateNewPassword: 'Creeate New Password',
  Code: 'Code',
  ConfirmPassword: ' Password (Confirm)',
  ResendVerification: 'Resend Verification Code',
  SendVerificationCode: 'Send Verification Code',
  GobackToLogin: 'Go Back To Login',
  MailVerification: 'Verify your email address for Password Change',
  ResetMyPassword: 'Reset my password',
  VerificationSendedToMail: 'Verification Code Sent To Your Email Address!',
  PleaseCheckToYourMailBox: 'Please check your e-mail box! ',
  ForPasswordConfirmation:
    'You can complete your password change process by clicking the link in the e-mail.',
};

export default locale;
