import _ from 'lodash';

const PrepareRow = (request, t) => {
  let currentRowList = [];
  currentRowList = request;
  _.forEach(currentRowList, (row, index) => {
    _.forEach(row.items, (rowsItem) => {
      let value;

      switch (rowsItem.type) {
        case 'number':
          value = _.toNumber(rowsItem.value);
          break;
        case 'money':
          value = _.toNumber(rowsItem.value);
          break;
        case 'boolean':
          value = _.eq(rowsItem.value, 'true');
          break;
        default:
          value = rowsItem.value;
      }
      row = _.assignIn(_.assignIn({}, { [rowsItem.code]: value }), row);
    });
    _.assignIn(
      row,
      _.assignIn({}, { createdDate: t('formatDate', { date: new Date(row.createdAt) }) })
    );
    _.assignIn(
      row,
      _.assignIn({}, { updatedDate: t('formatDate', { date: new Date(row.updatedAt) }) })
    );

    currentRowList[index] = row;
  });

  return currentRowList;
};

export default PrepareRow;
