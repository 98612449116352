import { lazy } from 'react';
import i18next from 'i18next';
import { authRoles } from 'app/auth';
import en from './i18n/en';
import tr from './i18n/tr';

i18next.addResourceBundle('en', 'Product', en);
i18next.addResourceBundle('tr', 'Product', tr);

i18next.addResourceBundle('en', 'ProductTable', en);
i18next.addResourceBundle('tr', 'ProductTable', tr);

const Product = lazy(() => import('./product/Product'));

const ProductsAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.authorized,
  routes: [
    {
      path: 'products',
      element: <Product />,
    },
  ],
};

export default ProductsAppConfig;
