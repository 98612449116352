import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);

const navigationConfig = [
  {
    id: 'applications',
    title: 'Applications',
    translate: 'APPLICATIONS',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'requests',
        title: 'Requests',
        translate: 'Requests',
        type: 'item',
        icon: 'receipt',
        url: 'requests',
        auth: authRoles.authorized,
        end: true,
      },
      {
        id: 'waiting list',
        title: 'Waiting List',
        type: 'item',
        translate: 'Open',
        icon: 'cached',
        url: 'waiting',
        auth: authRoles.partner,
      },
      {
        id: 'completed list',
        title: 'Completed List',
        type: 'item',
        translate: 'Completed',
        icon: 'done',
        url: 'completed',
        auth: authRoles.partner,
      },
      {
        id: 'settings',
        title: 'Settings',
        type: 'collapse',
        translate: 'Settings',
        icon: 'settings',
        auth: authRoles.authorized,
        children: [
          {
            id: 'products',
            title: 'Products',
            translate: 'Products',
            icon: 'label',
            type: 'item',
            url: 'products',
          },
          {
            id: 'contacts',
            title: 'Contacts',
            translate: 'Contacts',
            type: 'item',
            icon: 'account_box',
            url: 'users/all',
            end: true,
          },
        ],
      },
    ],
  },
];

export default navigationConfig;
