/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      firstname
      lastname
      email
      companyName
      vkn
      tckn
      storeCode
      storeName
      legalName
      currentCode
      taxOffice
      role
      accountCode
      phoneNumber
      address
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        firstname
        lastname
        email
        companyName
        vkn
        tckn
        storeCode
        storeName
        legalName
        currentCode
        taxOffice
        role
        accountCode
        phoneNumber
        address
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        username
        firstname
        lastname
        email
        companyName
        vkn
        tckn
        storeCode
        storeName
        legalName
        currentCode
        taxOffice
        role
        accountCode
        phoneNumber
        address
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRequestList = /* GraphQL */ `
  query GetRequestList($id: ID!) {
    getRequestList(id: $id) {
      id
      viewId
      status
      requestsSourceStatus
      errors {
        key
        name
        message
        status
      }
      columns {
        index
        field
        editable
        hide
        isNullable
        type
        isSystemProduct
        valueOptions
        style {
          width
          minWidth
          height
          flex
          headerClassName
          align
        }
        status
        error {
          key
          name
          message
          status
        }
      }
      updatedAt
      createdAt
      requests {
        items {
          id
          viewId
          status
          errors {
            key
            name
            message
            status
          }
          items {
            code
            productId
            status
            hasInvoiceLine
            isInvoice
            error {
              key
              name
              message
              status
            }
            value
            type
          }
          mailSendUserId
          requestSenderUserId
          owner
          lastUploadFilename
          updatedAt
          createdAt
          requestListID
          requestList {
            id
            viewId
            status
            requestsSourceStatus
            errors {
              key
              name
              message
              status
            }
            columns {
              index
              field
              editable
              hide
              isNullable
              type
              isSystemProduct
              valueOptions
              status
            }
            updatedAt
            createdAt
            requests {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
            owner
          }
          invoices {
            items {
              id
              viewId
              status
              owner
              updatedAt
              createdAt
              requestID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listRequestLists = /* GraphQL */ `
  query ListRequestLists(
    $filter: ModelRequestListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        viewId
        status
        requestsSourceStatus
        errors {
          key
          name
          message
          status
        }
        columns {
          index
          field
          editable
          hide
          isNullable
          type
          isSystemProduct
          valueOptions
          style {
            width
            minWidth
            height
            flex
            headerClassName
            align
          }
          status
          error {
            key
            name
            message
            status
          }
        }
        updatedAt
        createdAt
        requests {
          items {
            id
            viewId
            status
            errors {
              key
              name
              message
              status
            }
            items {
              code
              productId
              status
              hasInvoiceLine
              isInvoice
              value
              type
            }
            mailSendUserId
            requestSenderUserId
            owner
            lastUploadFilename
            updatedAt
            createdAt
            requestListID
            requestList {
              id
              viewId
              status
              requestsSourceStatus
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            invoices {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRequestLists = /* GraphQL */ `
  query SyncRequestLists(
    $filter: ModelRequestListFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRequestLists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        viewId
        status
        requestsSourceStatus
        errors {
          key
          name
          message
          status
        }
        columns {
          index
          field
          editable
          hide
          isNullable
          type
          isSystemProduct
          valueOptions
          style {
            width
            minWidth
            height
            flex
            headerClassName
            align
          }
          status
          error {
            key
            name
            message
            status
          }
        }
        updatedAt
        createdAt
        requests {
          items {
            id
            viewId
            status
            errors {
              key
              name
              message
              status
            }
            items {
              code
              productId
              status
              hasInvoiceLine
              isInvoice
              value
              type
            }
            mailSendUserId
            requestSenderUserId
            owner
            lastUploadFilename
            updatedAt
            createdAt
            requestListID
            requestList {
              id
              viewId
              status
              requestsSourceStatus
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            invoices {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      viewId
      status
      errors {
        key
        name
        message
        status
      }
      items {
        code
        productId
        status
        hasInvoiceLine
        isInvoice
        error {
          key
          name
          message
          status
        }
        value
        type
      }
      mailSendUserId
      requestSenderUserId
      owner
      lastUploadFilename
      updatedAt
      createdAt
      requestListID
      requestList {
        id
        viewId
        status
        requestsSourceStatus
        errors {
          key
          name
          message
          status
        }
        columns {
          index
          field
          editable
          hide
          isNullable
          type
          isSystemProduct
          valueOptions
          style {
            width
            minWidth
            height
            flex
            headerClassName
            align
          }
          status
          error {
            key
            name
            message
            status
          }
        }
        updatedAt
        createdAt
        requests {
          items {
            id
            viewId
            status
            errors {
              key
              name
              message
              status
            }
            items {
              code
              productId
              status
              hasInvoiceLine
              isInvoice
              value
              type
            }
            mailSendUserId
            requestSenderUserId
            owner
            lastUploadFilename
            updatedAt
            createdAt
            requestListID
            requestList {
              id
              viewId
              status
              requestsSourceStatus
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            invoices {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        owner
      }
      invoices {
        items {
          id
          viewId
          status
          errors {
            key
            name
            message
            status
          }
          items {
            code
            productId
            status
            hasInvoiceLine
            isInvoice
            error {
              key
              name
              message
              status
            }
            value
            type
          }
          owner
          updatedAt
          createdAt
          requestID
          request {
            id
            viewId
            status
            errors {
              key
              name
              message
              status
            }
            items {
              code
              productId
              status
              hasInvoiceLine
              isInvoice
              value
              type
            }
            mailSendUserId
            requestSenderUserId
            owner
            lastUploadFilename
            updatedAt
            createdAt
            requestListID
            requestList {
              id
              viewId
              status
              requestsSourceStatus
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            invoices {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        viewId
        status
        errors {
          key
          name
          message
          status
        }
        items {
          code
          productId
          status
          hasInvoiceLine
          isInvoice
          error {
            key
            name
            message
            status
          }
          value
          type
        }
        mailSendUserId
        requestSenderUserId
        owner
        lastUploadFilename
        updatedAt
        createdAt
        requestListID
        requestList {
          id
          viewId
          status
          requestsSourceStatus
          errors {
            key
            name
            message
            status
          }
          columns {
            index
            field
            editable
            hide
            isNullable
            type
            isSystemProduct
            valueOptions
            style {
              width
              minWidth
              height
              flex
              headerClassName
              align
            }
            status
            error {
              key
              name
              message
              status
            }
          }
          updatedAt
          createdAt
          requests {
            items {
              id
              viewId
              status
              mailSendUserId
              requestSenderUserId
              owner
              lastUploadFilename
              updatedAt
              createdAt
              requestListID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
          owner
        }
        invoices {
          items {
            id
            viewId
            status
            errors {
              key
              name
              message
              status
            }
            items {
              code
              productId
              status
              hasInvoiceLine
              isInvoice
              value
              type
            }
            owner
            updatedAt
            createdAt
            requestID
            request {
              id
              viewId
              status
              mailSendUserId
              requestSenderUserId
              owner
              lastUploadFilename
              updatedAt
              createdAt
              requestListID
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRequests = /* GraphQL */ `
  query SyncRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        viewId
        status
        errors {
          key
          name
          message
          status
        }
        items {
          code
          productId
          status
          hasInvoiceLine
          isInvoice
          error {
            key
            name
            message
            status
          }
          value
          type
        }
        mailSendUserId
        requestSenderUserId
        owner
        lastUploadFilename
        updatedAt
        createdAt
        requestListID
        requestList {
          id
          viewId
          status
          requestsSourceStatus
          errors {
            key
            name
            message
            status
          }
          columns {
            index
            field
            editable
            hide
            isNullable
            type
            isSystemProduct
            valueOptions
            style {
              width
              minWidth
              height
              flex
              headerClassName
              align
            }
            status
            error {
              key
              name
              message
              status
            }
          }
          updatedAt
          createdAt
          requests {
            items {
              id
              viewId
              status
              mailSendUserId
              requestSenderUserId
              owner
              lastUploadFilename
              updatedAt
              createdAt
              requestListID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
          owner
        }
        invoices {
          items {
            id
            viewId
            status
            errors {
              key
              name
              message
              status
            }
            items {
              code
              productId
              status
              hasInvoiceLine
              isInvoice
              value
              type
            }
            owner
            updatedAt
            createdAt
            requestID
            request {
              id
              viewId
              status
              mailSendUserId
              requestSenderUserId
              owner
              lastUploadFilename
              updatedAt
              createdAt
              requestListID
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      viewId
      status
      errors {
        key
        name
        message
        status
      }
      items {
        code
        productId
        status
        hasInvoiceLine
        isInvoice
        error {
          key
          name
          message
          status
        }
        value
        type
      }
      owner
      updatedAt
      createdAt
      requestID
      request {
        id
        viewId
        status
        errors {
          key
          name
          message
          status
        }
        items {
          code
          productId
          status
          hasInvoiceLine
          isInvoice
          error {
            key
            name
            message
            status
          }
          value
          type
        }
        mailSendUserId
        requestSenderUserId
        owner
        lastUploadFilename
        updatedAt
        createdAt
        requestListID
        requestList {
          id
          viewId
          status
          requestsSourceStatus
          errors {
            key
            name
            message
            status
          }
          columns {
            index
            field
            editable
            hide
            isNullable
            type
            isSystemProduct
            valueOptions
            style {
              width
              minWidth
              height
              flex
              headerClassName
              align
            }
            status
            error {
              key
              name
              message
              status
            }
          }
          updatedAt
          createdAt
          requests {
            items {
              id
              viewId
              status
              mailSendUserId
              requestSenderUserId
              owner
              lastUploadFilename
              updatedAt
              createdAt
              requestListID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
          owner
        }
        invoices {
          items {
            id
            viewId
            status
            errors {
              key
              name
              message
              status
            }
            items {
              code
              productId
              status
              hasInvoiceLine
              isInvoice
              value
              type
            }
            owner
            updatedAt
            createdAt
            requestID
            request {
              id
              viewId
              status
              mailSendUserId
              requestSenderUserId
              owner
              lastUploadFilename
              updatedAt
              createdAt
              requestListID
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        viewId
        status
        errors {
          key
          name
          message
          status
        }
        items {
          code
          productId
          status
          hasInvoiceLine
          isInvoice
          error {
            key
            name
            message
            status
          }
          value
          type
        }
        owner
        updatedAt
        createdAt
        requestID
        request {
          id
          viewId
          status
          errors {
            key
            name
            message
            status
          }
          items {
            code
            productId
            status
            hasInvoiceLine
            isInvoice
            error {
              key
              name
              message
              status
            }
            value
            type
          }
          mailSendUserId
          requestSenderUserId
          owner
          lastUploadFilename
          updatedAt
          createdAt
          requestListID
          requestList {
            id
            viewId
            status
            requestsSourceStatus
            errors {
              key
              name
              message
              status
            }
            columns {
              index
              field
              editable
              hide
              isNullable
              type
              isSystemProduct
              valueOptions
              status
            }
            updatedAt
            createdAt
            requests {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
            owner
          }
          invoices {
            items {
              id
              viewId
              status
              owner
              updatedAt
              createdAt
              requestID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInvoices = /* GraphQL */ `
  query SyncInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInvoices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        viewId
        status
        errors {
          key
          name
          message
          status
        }
        items {
          code
          productId
          status
          hasInvoiceLine
          isInvoice
          error {
            key
            name
            message
            status
          }
          value
          type
        }
        owner
        updatedAt
        createdAt
        requestID
        request {
          id
          viewId
          status
          errors {
            key
            name
            message
            status
          }
          items {
            code
            productId
            status
            hasInvoiceLine
            isInvoice
            error {
              key
              name
              message
              status
            }
            value
            type
          }
          mailSendUserId
          requestSenderUserId
          owner
          lastUploadFilename
          updatedAt
          createdAt
          requestListID
          requestList {
            id
            viewId
            status
            requestsSourceStatus
            errors {
              key
              name
              message
              status
            }
            columns {
              index
              field
              editable
              hide
              isNullable
              type
              isSystemProduct
              valueOptions
              status
            }
            updatedAt
            createdAt
            requests {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
            owner
          }
          invoices {
            items {
              id
              viewId
              status
              owner
              updatedAt
              createdAt
              requestID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      viewId
      isActive
      isSystemProduct
      name
      description
      code
      accountCode
      isEditable
      isHidden
      isNullable
      hasInvoiceLine
      valueOptions
      type
      style {
        width
        minWidth
        height
        flex
        headerClassName
        align
      }
      updatedAt
      createdAt
      tags {
        items {
          id
          viewId
          isActive
          text
          content
          updatedAt
          createdAt
          productID
          product {
            id
            viewId
            isActive
            isSystemProduct
            name
            description
            code
            accountCode
            isEditable
            isHidden
            isNullable
            hasInvoiceLine
            valueOptions
            type
            style {
              width
              minWidth
              height
              flex
              headerClassName
              align
            }
            updatedAt
            createdAt
            tags {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        viewId
        isActive
        isSystemProduct
        name
        description
        code
        accountCode
        isEditable
        isHidden
        isNullable
        hasInvoiceLine
        valueOptions
        type
        style {
          width
          minWidth
          height
          flex
          headerClassName
          align
        }
        updatedAt
        createdAt
        tags {
          items {
            id
            viewId
            isActive
            text
            content
            updatedAt
            createdAt
            productID
            product {
              id
              viewId
              isActive
              isSystemProduct
              name
              description
              code
              accountCode
              isEditable
              isHidden
              isNullable
              hasInvoiceLine
              valueOptions
              type
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProducts = /* GraphQL */ `
  query SyncProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        viewId
        isActive
        isSystemProduct
        name
        description
        code
        accountCode
        isEditable
        isHidden
        isNullable
        hasInvoiceLine
        valueOptions
        type
        style {
          width
          minWidth
          height
          flex
          headerClassName
          align
        }
        updatedAt
        createdAt
        tags {
          items {
            id
            viewId
            isActive
            text
            content
            updatedAt
            createdAt
            productID
            product {
              id
              viewId
              isActive
              isSystemProduct
              name
              description
              code
              accountCode
              isEditable
              isHidden
              isNullable
              hasInvoiceLine
              valueOptions
              type
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      viewId
      isActive
      text
      content
      updatedAt
      createdAt
      productID
      product {
        id
        viewId
        isActive
        isSystemProduct
        name
        description
        code
        accountCode
        isEditable
        isHidden
        isNullable
        hasInvoiceLine
        valueOptions
        type
        style {
          width
          minWidth
          height
          flex
          headerClassName
          align
        }
        updatedAt
        createdAt
        tags {
          items {
            id
            viewId
            isActive
            text
            content
            updatedAt
            createdAt
            productID
            product {
              id
              viewId
              isActive
              isSystemProduct
              name
              description
              code
              accountCode
              isEditable
              isHidden
              isNullable
              hasInvoiceLine
              valueOptions
              type
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        viewId
        isActive
        text
        content
        updatedAt
        createdAt
        productID
        product {
          id
          viewId
          isActive
          isSystemProduct
          name
          description
          code
          accountCode
          isEditable
          isHidden
          isNullable
          hasInvoiceLine
          valueOptions
          type
          style {
            width
            minWidth
            height
            flex
            headerClassName
            align
          }
          updatedAt
          createdAt
          tags {
            items {
              id
              viewId
              isActive
              text
              content
              updatedAt
              createdAt
              productID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTags = /* GraphQL */ `
  query SyncTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        viewId
        isActive
        text
        content
        updatedAt
        createdAt
        productID
        product {
          id
          viewId
          isActive
          isSystemProduct
          name
          description
          code
          accountCode
          isEditable
          isHidden
          isNullable
          hasInvoiceLine
          valueOptions
          type
          style {
            width
            minWidth
            height
            flex
            headerClassName
            align
          }
          updatedAt
          createdAt
          tags {
            items {
              id
              viewId
              isActive
              text
              content
              updatedAt
              createdAt
              productID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSystemError = /* GraphQL */ `
  query GetSystemError($id: ID!) {
    getSystemError(id: $id) {
      id
      owner
      error {
        key
        name
        message
        status
      }
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSystemErrors = /* GraphQL */ `
  query ListSystemErrors(
    $filter: ModelSystemErrorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemErrors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        error {
          key
          name
          message
          status
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSystemErrors = /* GraphQL */ `
  query SyncSystemErrors(
    $filter: ModelSystemErrorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSystemErrors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        error {
          key
          name
          message
          status
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
