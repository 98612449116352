const locale = {
  NOTIFICATION_MESSAGE_ADD_REQUEST_LIST: '{{viewId}} talep listesi eklendi',
  NOTIFICATION_MESSAGE_UPDATE_REQUEST_LIST: '{{viewId}} talep listesi güncellendi',
  NOTIFICATION_MESSAGE_DELETE_REQUEST_LIST: '{{viewId}} talep listesi silindi',
  NOTIFICATION_MESSAGE_DELETE_REQUEST_WAITING: '{{viewId}} talep silindi',
  PARTNER_PENDING_NOTIFICATION_MESSAGE_UPDATE_WAITING: 'Yeni talep geldi',
  PARTNER_VALID_NOTIFICATION_MESSAGE_UPDATE_WAITING: 'Fatura doğrulandı',
  PARTNER_ERROR_NOTIFICATION_MESSAGE_UPDATE_WAITING: 'Hatalı fatura girildi',
  PARTNER_APPROVED_NOTIFICATION_MESSAGE_UPDATE_WAITING: 'Fatura onaya gönderildi',
};

export default locale;
