import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import moment from 'moment';
import 'moment/locale/tr';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      'Welcome to React': 'Welcome to React and react-i18next',
    },
  },
};
i18n.on('languageChanged', function (lng) {
  moment.locale(lng);
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'tr',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
      format(value, rawFormat, lng) {
        // Format Date
        if (value instanceof Date)
          return `${moment(value).format('L')} ${moment(value).format('LT')}`;
        // Format Currency
        const [format, ...additionalValues] = rawFormat.split(',').map((v) => v.trim());
        if (format === 'price') {
          const result = Intl.NumberFormat(lng, {
            style: 'currency',
            currency: additionalValues[0],
          }).format(value);
          if (lng === 'tr') return `${result.replace('₺', '')} TL`;
          return result;
        }
        return value;
      },
    },
  });

export default i18n;
