import { Auth, API } from 'aws-amplify';
import * as queries from 'graphql/queries';

class CognitoService {
  init(success) {
    // if (
    //   Object.entries(COGNITO_AUTH_CONFIG).length === 0 &&
    //   COGNITO_AUTH_CONFIG.constructor === Object
    // ) {
    //   if (process.env.NODE_ENV === 'development') {
    //     console.warn(
    //       'Missing Auth0 configuration at src/app/services/auth0Service/auth0ServiceConfig.js'
    //     );
    //   }
    //   success(false);
    // }
    this.auth = Auth;
    success(true);
  }

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      try {
        const response = this.auth.signIn(email, password);
        resolve(response);
      } catch (error) {
        reject(error);
        console.log('error signing in', error);
      }
    });
  };

  onAuthenticated = () => {
    return this.auth.currentAuthenticatedUser();
  };

  signOut = () => {
    localStorage.removeItem('allGroups');
    return this.auth.signOut({ global: true });
  };

  updateUserData = (userMetadata) => {
    const { authUser, settings } = userMetadata;

    return this.auth.updateUserAttributes(authUser, {
      'custom:settings': JSON.stringify(settings),
    });
  };

  sendVerificationCode = (email) => {
    return new Promise((resolve, reject) => {
      try {
        const response = Auth.forgotPassword(email);
        resolve(response);
      } catch (error) {
        reject(error);
        console.log('error signing in', error);
      }
    });
  };

  changePassword = (email, code, password) => {
    return new Promise((resolve, reject) => {
      try {
        const response = Auth.forgotPasswordSubmit(email, code, password);
        resolve(response);
      } catch (error) {
        reject(error);
        console.log('error signing in', error);
      }
    });
  };

  getUser = () => {
    return new Promise((resolve, reject) => {
      try {
        const response = API.graphql({
          query: queries.listUsers,
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        });
        resolve(response);
      } catch (error) {
        reject(error);
        console.log('error signing in', error);
      }
    });
  };

  getUserRoles = () => async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    const groups = currentUser.signInUserSession.accessToken.payload['cognito:groups'];
    return groups;
  };

  signUpWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      try {
        const response = Auth.signUp({
          username: email,
          password,
          attributes: {
            email, // optional
            // optional - E.164 number convention
            // other custom attributes
          },
        });
        resolve(response);
        console.log(response);
      } catch (error) {
        reject(error);
        console.log('error signing up:', error);
      }
    });
  };
}
const instance = new CognitoService();

export default instance;
