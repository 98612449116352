import { Navigate } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import appsConfigs from 'app/main/apps/appsConfigs';
import FuseLoading from '@fuse/core/FuseLoading';
import pagesConfigs from 'app/main/pages/pagesConfigs';
import LoginConfig from 'app/main/login/LoginConfig';
import LogoutConfig from 'app/main/logout/LogoutConfig';
import { authRoles } from '../auth';

const routeConfigs = [...appsConfigs, ...pagesConfigs, LoginConfig, LogoutConfig];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, authRoles.all),
  {
    path: '/',
    element: <Navigate to="requests" />,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '*',
    element: <Navigate to="pages/errors/error-404" />,
  },
];

export default routes;
