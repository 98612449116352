import i18next from 'i18next';
import Error404PageConfig from './errors/404/Error404PageConfig';
import Error500PageConfig from './errors/500/Error500PageConfig';
import ForgotPassword2PageConfig from './auth/forgot-password-2/forgot-password/ForgotPassword2PageConfig';
import ResetPassword2PageConfig from './auth/reset-password/ResetPassword2PageConfig';
import MailSendedPageConfig from './auth/mail-sended/MailSendedPageConfig';
import en from './i18n/en';
import tr from './i18n/tr';

i18next.addResourceBundle('en', 'ResetPassword2Page', en);
i18next.addResourceBundle('tr', 'ResetPassword2Page', tr);

i18next.addResourceBundle('en', 'ForgotPassword2Page', en);
i18next.addResourceBundle('tr', 'ForgotPassword2Page', tr);

i18next.addResourceBundle('en', 'MailSendedPage', en);
i18next.addResourceBundle('tr', 'MailSendedPage', tr);

const pagesConfigs = [
  Error404PageConfig,
  Error500PageConfig,
  ForgotPassword2PageConfig,
  ResetPassword2PageConfig,
  MailSendedPageConfig,
];

export default pagesConfigs;
