const locale = {
  APPLICATIONS: 'Programlar',
  Requests: 'Talep Listeleri',
  Contacts: 'Kullanicilar',
  Settings: 'Ayarlar',
  Products: 'Ürünler',
  Partners: 'İş Ortakları',
  Open: 'Açık Talepler',
  Completed: 'Tamamlanmış',
};

export default locale;
