import BrowserRouter from '@fuse/core/BrowserRouter';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import { SnackbarProvider } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { selectCurrLangDir } from 'app/store/i18nSlice';
import withAppProviders from './withAppProviders';
import '@fake-db';
import { AuthModeStrategyType, DataStore, Hub, Predicates, syncExpression } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import _ from 'lodash';
import moment from 'moment';

import { Invoice, Product, Request, RequestList, Tag, User } from '../models';
import { Auth, authRoles } from './auth';
import { setPageLoading } from './main/apps/requests/store/requestsSlice';
import { setWaitingPageLoading } from './main/apps/franchise/store/waitingSlice';
import { setNetworkStatus, setGlobalPageLoading } from './store/fuse/settingsSlice';
import CognitoService from './services/cognitoService';
import AllSubscriptions from './subscriptions/allSubscriptions';

/**
 * Axios HTTP Request defaults
 */
// axios.defaults.baseURL = "https://9tzyt14qf2.execute-api.eu-west-1.amazonaws.com";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

const emotionCacheOptions = {
  rtl: {
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
  ltr: {
    key: 'muiltr',
    stylisPlugins: [],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
};

const App = () => {
  const langDirection = useSelector(selectCurrLangDir);
  const dispatch = useDispatch();

  const frUserChecker = async () => {
    const currentUser = await CognitoService.onAuthenticated();
    const roles = currentUser.signInUserSession.idToken.payload['cognito:groups'];
    const isFRUser = _.isEmpty(roles.filter((e) => authRoles.authorized.includes(e)));

    return {
      isFRUser,
      userId: currentUser.attributes.sub,
    };
  };

  const getDateRange = async () => {
    const currentDate = moment().add(1, 'day').format('YYYY-MM-DD');
    const threeMonthsEarlyDate = moment().add(-3, 'months').format('YYYY-MM-DD');

    return {
      threeMonthsEarlyDate,
      currentDate,
    };
  };

  DataStore.configure({
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
    maxRecordsToSync: 100000,
    syncExpressions: [
      syncExpression(RequestList, async () => {
        const { threeMonthsEarlyDate, currentDate } = await getDateRange();

        return (requestList) =>
          requestList.updatedAt('between', [threeMonthsEarlyDate, currentDate]);
      }),
      syncExpression(Request, async () => {
        const { threeMonthsEarlyDate, currentDate } = await getDateRange();
        const currentUser = await frUserChecker();

        if (currentUser.isFRUser) {
          return (request) =>
            request
              .updatedAt('between', [threeMonthsEarlyDate, currentDate])
              .owner('eq', currentUser.userId);
        }

        return (request) => request.updatedAt('between', [threeMonthsEarlyDate, currentDate]);
      }),
      syncExpression(Invoice, async () => {
        const { threeMonthsEarlyDate, currentDate } = await getDateRange();
        const currentUser = await frUserChecker();

        if (currentUser.isFRUser) {
          return (invoice) =>
            invoice
              .updatedAt('between', [threeMonthsEarlyDate, currentDate])
              .owner('eq', currentUser.userId);
        }

        return (invoice) => invoice.updatedAt('between', [threeMonthsEarlyDate, currentDate]);
      }),
      syncExpression(Product, async () => {
        const currentUser = await frUserChecker();

        if (currentUser.isFRUser) {
          return (product) => product.id('eq', '0');
        }

        return Predicates.ALL;
      }),
      syncExpression(Tag, async () => {
        const currentUser = await frUserChecker();

        if (currentUser.isFRUser) {
          return (tag) => tag.id('eq', '0');
        }

        return Predicates.ALL;
      }),
      syncExpression(User, async () => {
        const currentUser = await frUserChecker();

        if (currentUser.isFRUser) {
          return (user) => user.id('eq', currentUser.userId);
        }

        return Predicates.ALL;
      }),
    ],
  });

  AllSubscriptions();

  Hub.listen('datastore', async (hubData) => {
    const { event, data } = hubData.payload;
    console.log('DATASTORE EVENT: ', event);
    if (event === 'ready') {
      // debugger;
      dispatch(setGlobalPageLoading(false));
    } else if (event === 'networkStatus') {
      dispatch(setNetworkStatus(data.active));
    } else if (event === 'storageSubscribed') {
      // debugger;
      // dispatch(setGlobalPageLoading(true));
      dispatch(setPageLoading(true));
      dispatch(setWaitingPageLoading(true));
    } else if (event === 'syncQueriesReady') {
      // dispatch(setGlobalPageLoading(false));
      dispatch(setPageLoading(false));
      dispatch(setWaitingPageLoading(false));
      // debugger;
    }
  });

  Hub.listen('auth', async (data) => {
    if (data.payload.event === 'signOut') {
      // await DataStore.stop();
      await DataStore.clear();
    } else if (data.payload.event === 'signIn') {
      // debugger;
      await DataStore.start();
    }
  });

  return (
    <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
      <Auth>
        <BrowserRouter>
          <FuseAuthorization>
            <FuseTheme>
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                classes={{
                  containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
                }}
              >
                <FuseLayout />
              </SnackbarProvider>
            </FuseTheme>
          </FuseAuthorization>
        </BrowserRouter>
      </Auth>
    </CacheProvider>
  );
};
export default withAppProviders(App)();
