const locale = {
  AccountCode: 'Account Code',
  Active: 'ACTIVE',
  Add: 'Add',
  Code: 'Code',
  Content: 'Content',
  CreateDate: 'Create Date',
  Description: 'Description',
  EditProduct: 'Edit Product',
  ERROR: 'ERROR',
  IsActive: 'Active',
  IsEditable: 'Editable',
  IsHideable: 'Hideable',
  IsNullable: 'Nullable',
  IsSystemProduct: 'System Product',
  MESSAGE_SAME_CONTENT_WARNING: 'There is another text that provides the same condition.',
  MESSAGE_DELETE_TAG_DIALOG: 'The tags below will be deleted, do you agree?',
  Name: 'Name',
  NewProduct: 'New Product',
  NewTag: 'New Tag',
  NO_TAG_MESSAGE: 'There are no Tags!',
  NO_TAG_LINK: 'Go back to Products Page',
  NoProducts: 'There are no Products!',
  NoRequests: 'There are no Requests!',
  OK: 'OK',
  Passive: 'PASSIVE',
  Products: 'Products',
  Requests: 'Requests',
  Save: 'Save',
  Search: 'Search',
  Status: 'Status',
  SENDMAIL: 'SEND MAIL',
  SERVER_STATUS_CONNECTED: 'Online',
  SERVER_STATUS_DISCONNECTED: 'Offline',
  Text: 'Text',
  Type: 'Type',
  UpdateDate: 'Update Date',
  Upload: 'Upload',
  UPLOAD: 'UPLOAD',
  ValueOptions: 'Value Options',
  ValueOptionsPlaceholder: 'Select multiple value options',
  ViewId: 'Id',
  BackToProducts: 'Back to Products',
  Tags: 'Tags',
  NoTags: 'No Tags!',
  HasInvoiceLine: 'Has Invoice Line',
  CURRENT_LANGUAGE: 'enUS',
  formatDate: '{{date, dd/MM/yyyy}}',
};

export default locale;
