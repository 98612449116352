const locale = {
  LoginToYourAccount: 'Hesabınıza giriş yapın',
  Welcome: "Masraff'a",
  Login: 'Giriş Yap',
  ToThe: '',
  Email: 'Email',
  Password: 'Şifre',
  RememberMe: 'Beni Hatırla',
  Masraff: 'Hoşgeldiniz',
  AccountsPayableProcesses: 'Borç Hesapları Yönetim Süreçleri',
  ForgotPassword: 'Şifremi Unuttum',
  UserNotFoundException: 'Kullanıcı bulunamadı',
  NotAuthorizedException: 'Hatalı kullanıcı adı ve şifre girdiniz',
};

export default locale;
