import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { DataStore, SortDirection } from 'aws-amplify';
import _ from 'lodash';

import { Invoice, Request } from '../../../../../models';
import CognitoService from '../../../../services/cognitoService';

export const getWaitingDetail = createAsyncThunk(
  'waitingDetailApp/waitingDetail/getWaitingDetail',
  async (params, { dispatch, getState }) => {
    const { openingRequestId } = getState().waitingDetailApp.waitingDetail;

    let currentRequestId = openingRequestId;
    let isLoadingDisable = true;
    if (!_.isEmpty(params)) {
      const {
        routeParams: { id },
      } = params;
      currentRequestId = id;
      isLoadingDisable = false;
    }

    if (!isLoadingDisable) {
      dispatch(setWaitingDetailPageLoading(true));
    }

    const currentUser = await CognitoService.onAuthenticated();
    const request = await DataStore.query(Request, currentRequestId);
    const invoices = (
      await DataStore.query(Invoice, (c) => c.owner('eq', currentUser.attributes.sub), {
        sort: (s) => s.updatedAt(SortDirection.DESCENDING),
      })
    ).filter((c) => c.request.id === request.id);

    return {
      request,
      invoice: invoices[0],
      isLoadingDisable,
    };
  }
);

const waitingDetailAdapter = createEntityAdapter({});

export const { selectAll: selectWaitingDetail, selectById: selectWaitingDetailById } =
  waitingDetailAdapter.getSelectors((state) => state.waitingDetailApp.waitingDetail);

const waitingDetailSlice = createSlice({
  name: 'waitingDetailApp/waitingDetail',
  initialState: waitingDetailAdapter.getInitialState({
    waitingDetailPageLoading: true,
    waitingDetail: {},
    invoice: {},
    openingRequestId: null,
    storeList: [],
    sortModel: [
      {
        field: 'updatedDate',
        sort: 'desc',
      },
    ],
    filterModel: undefined,
    selectionModel: [],
    limit: 10,
  }),
  reducers: {
    setWaitingDetailPageLoading: {
      reducer: (state, action) => {
        state.waitingDetailPageLoading = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
  },
  extraReducers: {
    [getWaitingDetail.fulfilled]: (state, action) => {
      const { request, invoice, isLoadingDisable } = action.payload;
      state.invoice = invoice;
      state.waitingDetail = request;
      state.openingRequestId = request.id;
      if (!isLoadingDisable) {
        state.waitingDetailPageLoading = false;
      }
    },
  },
});

export const { newProduct, resetProduct, setWaitingDetailPageLoading } = waitingDetailSlice.actions;

export default waitingDetailSlice.reducer;
