import { lazy } from 'react';
import { authRoles } from '../../../../../auth';

const ForgotPassword2Page = lazy(() => import('./ForgotPassword2Page'));

const ForgotPassword2PageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'forgot-password',
      element: <ForgotPassword2Page />,
    },
  ],
};

export default ForgotPassword2PageConfig;
