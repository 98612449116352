const locale = {
  requestsText: 'Talep Listeleri',
  searchText: 'Ara',
  sendMail: 'Mail Gönder',
  mailSent: 'Mail Gönderildi',
  requestListTableColumnNameId: 'No',
  requestListTableColumnNameCreatedAt: 'Oluşturulma Tarihi',
  requestListTableColumnNameUpdatedAt: 'Güncellenme Tarihi',
  requestListTableColumnNameSourceStatus: 'Oluşturulma Türü',
  requestListTableColumnNameStatus: 'Durum',
  requestListTableHeaderFileUploadPercentage: '% {{percentage}}',
  UPLOAD: 'Yükleme',
  MANUEL: 'El ile',
  PENDING: 'Bekliyor',
  ERROR: 'Hata',
  VALID: 'Geçerli',
  COMPLETED: 'Tamamlandı',
  EMAIL_SENDED: 'E-Posta Gönderildi',
  PARTNER_PENDING: 'Partnerda Bekliyor',
  PARTNER_ERROR: 'Partner Hatası',
  PARTNER_VALID: 'Partner Tamamladı',
  PARTNER_APPROVED: 'Partner Onayladı',
  COLUMN_NULL_ERROR: 'Kolon boş',
  COLUMN_PRODUCT_NOT_FOUND: 'Kolon bulunamadı',
  CELL_PRODUCT_NOT_FOUND: 'Kolon bulunamadı',
  PSB: 'Performans Desteği',
  CELL_NULL_ERROR: '{{code}} bulunamadı',
  STC: 'Depo Kodu',
  COLUMN_NAME_ID: 'Sistem No',
  COLUMN_NAME_VIEW_ID: 'No',
  COLUMN_NAME_STC: 'Depo Kodu',
  COLUMN_NAME_STN: 'Depo Adı',
  COLUMN_NAME_TOT: 'Toplam Fatura',
  COLUMN_NAME_TOV: 'KDV Dahil',
  COLUMN_NAME_TYP: 'Tür',
  COLUMN_NAME_PSB: 'Performans Destek Primi',
  COLUMN_NAME_RCO: 'Ciro Komisyon',
  COLUMN_NAME_PPO: 'Sipariş Başı Ödeme',
  COLUMN_NAME_TIP: 'Bahşiş',
  COLUMN_NAME_SSB: 'Startup Destek',
  COLUMN_NAME_CREATED_DATE: 'Oluşturulma Tarihi',
  COLUMN_NAME_UPDATED_DATE: 'Güncellenme Tarihi',
  COLUMN_NAME_ERROR_LIST: 'Hata Listesi',
  COLUMN_NAME_STATUS: 'Durum',
  COLUMN_NAME_SENDMAIL: '',
  COLUMN_NAME_DOWNLOAD: '',
  COLUMN_NAME_SYSTEM_INDEX: 'Sıra',
  BACK_REQUESTS_NAME: 'Talep Listelerine Geri Dön',
  REQUESTS_DETAIL_NAME: 'Talepler Detayı',
  CURRENT_LANGUAGE: 'trTR',
  MESSAGE_UPLOAD_FILE_SUCCESS: 'Dosya başarıyla yüklendi.',
  MESSAGE_UPLOAD_FILE_ERROR: 'Yükleme sırasında beklenmeyen bir hata ile karşılaşıldı.',
  MESSAGE_SEND_MAIL_SUCCESS: 'Mail gönderme işlemi başladı',
  MESSAGE_SEND_MAIL_ERROR: 'Mail gönderilemedi',
  MESSAGE_REMOVE_REQUEST_LIST: 'Talep listesi başarıyla silindi.',
  MESSAGE_CANT_DELETE_REQUEST_LIST_ERROR:
    'Talep listesinin içinde durumu silinmeye uygun olmayan bir talep bulunmaktadır.',
  momentLang: 'tr',
  Delete: 'Sil',
  Cancel: 'Vazgeç',
  Download: 'İndir',
  Agree: 'Onayla',
  SendMail: 'Mail Gönder',
  formatDate: '{{date, dd/MM/yyyy}}',
  formatCurrency: '{{value, price, TRY}}',
  NO_ROWS: 'Herhangi bir Talep Yok',
  SEND_TO_APPROVAL: 'Onaya Gönder',
  MESSAGE_SEND_MAIL_DIALOG_TITLE: 'E-Posta Gönderme',
  MESSAGE_SEND_MAIL_DIALOG_CONTENT:
    'Seçili talep listesindeki bütün partnerlere E-posta göndermeyi onaylıyor musunuz?',
  REQUEST_HEADER: 'Talepleri',
  REQUEST_HEADER_FAB_ACTION_ADD: 'Ekle',
  REQUEST_HEADER_FAB_ACTION_DELETE: 'Sil',
  REQUEST_HEADER_FAB_ACTION_EXPORT: 'Çıktı',
  REQUEST_MESSAGE_DELETE_REQUEST_LIST_DIALOG: 'Hepsini silmeyi onaylıyor musunuz?',
  NO_REQUEST_MESSAGE: 'Herhangi bir talep bulunmuyor',
  NO_REQUEST_LINK: 'Talep listelerine geri dön',
  DELETE_REQUEST_ERROR_MESSAGE: 'Tamamlanan talepler silinemez',
  PROCESS: 'İşlem',
  MESSAGE_DELETE_REQUEST_DIALOG: 'Aşağıdaki talepler silinecek, onaylıyor musunuz?',
  MESSAGE_SEND_REQUEST_DIALOG:
    '{{viewId}} talebi {{SC}} depoya onaya gonderiliyor. Onayliyor musunuz?',
  MESSAGE_SEND_REQUEST: '{{viewId}} talebi onaya gönderildi',
  PAGINATION_PREV: 'Geri',
  PAGINATION_NEXT: 'İleri',
  SERVER_STATUS_CONNECTED: 'Online',
  SERVER_STATUS_DISCONNECTED: 'Offline',

  MESSAGE_ADD_REQUEST_LIST: '{{viewId}} talep listesi eklendi',
  MESSAGE_UPDATE_REQUEST_LIST: '{{viewId}} talep listesi güncellendi',
  MESSAGE_DELETE_REQUEST_LIST: '{{viewId}} talep listesi silindi',
  MESSAGE_DELETE_REQUEST_LIST_DIALOG: 'Aşağıdaki talep listeleri silinecek, onaylıyor musunuz?',
  REQUESTS_NO_ROWS: 'Herhangi bir talep listesi yok',
  MESSAGE_ADD_REQUEST: '{{viewId}} talep eklendi',
  MESSAGE_UPDATE_REQUEST: '{{viewId}} talep güncellendi',
  MESSAGE_DELETE_REQUEST: '{{viewId}} talep silindi',
  NO_OPTION: 'Sonuç bulunamadı',
};

export default locale;
