const locale = {
  APPLICATIONS: 'Applications',
  Requests: 'Request List',
  Contacts: 'Users',
  Settings: 'Settings',
  Products: 'Products',
  Partners: 'Partners',
  Open: 'Open Requests',
  Completed: 'Completed',
};

export default locale;
