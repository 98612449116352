import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { API, Auth, DataStore, SortDirection } from 'aws-amplify';
import _ from 'lodash';

import { Request, RequestList, RequestStatus } from '../../../../../models';
import { addNotification } from '../../../../fuse-layouts/shared-components/notificationPanel/store/dataSlice';
import NotificationModel from '../../../../fuse-layouts/shared-components/notificationPanel/model/NotificationModel';
import { authRoles } from '../../../../auth';

export const getWaiting = createAsyncThunk(
  'franchiseApp/waiting/getWaiting',
  async (val, { dispatch, getState }) => {
    dispatch(setWaitingDataGridLoading(true));

    const authUser = getState().auth.user;
    const isFRUser = _.isEmpty(authUser.role.filter((e) => authRoles.authorized.includes(e)));
    let requests = null;

    if (isFRUser) {
      requests = await DataStore.query(
        Request,
        (c) =>
          c
            .and((c) => c.owner('eq', authUser.uid))
            .or((c) =>
              c
                .status('eq', RequestStatus.PARTNER_PENDING)
                .status('eq', RequestStatus.PARTNER_ERROR)
                .status('eq', RequestStatus.PARTNER_VALID)
                .status('eq', RequestStatus.PARTNER_APPROVED)
            ),
        {
          sort: (s) => s.updatedAt(SortDirection.DESCENDING),
        }
      );
    } else {
      requests = await DataStore.query(
        Request,
        (c) =>
          c.or((c) =>
            c
              .status('eq', RequestStatus.PARTNER_PENDING)
              .status('eq', RequestStatus.PARTNER_ERROR)
              .status('eq', RequestStatus.PARTNER_VALID)
          ),
        {
          sort: (s) => s.updatedAt(SortDirection.DESCENDING),
        }
      );
    }

    return requests;
  }
);

export const setWaiting = createAsyncThunk(
  'franchiseApp/waiting/setWaiting',
  async (params, { dispatch, getState }) => {
    const { items } = params;
    dispatch(setWaitingDataGridLoading(true));

    const authUser = getState().auth.user;
    const requests = await DataStore.query(
      Request,
      (c) =>
        c
          .and((c) => c.owner('eq', authUser.uid))
          .or((c) =>
            c
              .status('eq', RequestStatus.PARTNER_PENDING)
              .status('eq', RequestStatus.PARTNER_ERROR)
              .status('eq', RequestStatus.PARTNER_VALID)
              .status('eq', RequestStatus.PARTNER_APPROVED)
          ),
      {
        sort: (s) => s.updatedAt(SortDirection.DESCENDING),
      }
    );

    return requests;
  }
);

export const sendApproveWaiting = createAsyncThunk(
  'franchiseApp/waiting/sendApproveWaiting',
  async (params, { dispatch, getState }) => {
    const {
      successMessage,
      errorMessage,
      requestId,
      requestVersion,
      requestCreatedAt,
      requestListId,
      requestTotalAmount,
      partnerUsername,
      lang,
    } = params;
    dispatch(setWaitingDataGridLoading(true));

    const requestList = await DataStore.query(RequestList, requestListId);
    const data = {
      requestId,
      requestVersion,
      requestCreatedAt,
      requestListId,
      requestListVersion: requestList._version,
      language: lang,
      email: {
        name: 'Yemeksepeti',
        totalAmount: requestTotalAmount,
        partnerName: partnerUsername,
      },
    };

    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const requestInfo = {
      body: { data },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const result = await API.post('triggerapi', '/send-approve', requestInfo);
    const isResultSuccess = !_.isEmpty(result) && result.isSuccess;

    dispatch(
      addNotification(
        NotificationModel({
          message: isResultSuccess ? successMessage : errorMessage,
          options: { variant: isResultSuccess ? 'success' : 'error' },
        })
      )
    );

    return result;
  }
);

export const updateNotificationWaiting = createAsyncThunk(
  'franchiseApp/waiting/updateNotificationWaiting',
  async (params, { dispatch, getState }) => {
    dispatch(addNotification(NotificationModel(params)));

    return null;
  }
);
export const deleteNotificationWaiting = createAsyncThunk(
  'franchiseApp/waiting/deleteNotificationWaiting',
  async (params, { dispatch, getState }) => {
    dispatch(addNotification(NotificationModel(params)));

    return null;
  }
);

export const insertWaiting = createAsyncThunk(
  'franchiseApp/waiting/addWaiting',
  async (params, { dispatch, getState }) => {
    const { data } = params;
    dispatch(setWaitingDataGridLoading(true));

    return data;
  }
);
export const updateWaiting = createAsyncThunk(
  'franchiseApp/waiting/updateWaiting',
  async (params, { dispatch, getState }) => {
    const { data } = params;
    dispatch(setWaitingDataGridLoading(true));

    return data;
  }
);
export const deleteWaiting = createAsyncThunk(
  'franchiseApp/waiting/deleteWaiting',
  async (params, { dispatch, getState }) => {
    const { data } = params;
    dispatch(setWaitingDataGridLoading(true));

    return data.id;
  }
);

const waitingAdapter = createEntityAdapter({});

export const { selectAll: selectWaiting, selectById: selectWaitingById } =
  waitingAdapter.getSelectors((state) => state.franchiseApp.waiting);

const waitingSlice = createSlice({
  name: 'franchiseApp/waiting',
  initialState: waitingAdapter.getInitialState({
    searchText: '',
    waitingDataGridLoading: false,
    waitingPageLoading: true,
    waitingPageLimit: 10,
    waitingSortModel: [
      {
        field: 'updatedAt',
        sort: 'desc',
      },
    ],
    waitingFilterModel: undefined,
  }),
  reducers: {
    setWaitingSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setWaitingPageLoading: {
      reducer: (state, action) => {
        state.waitingPageLoading = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
    setWaitingDataGridLoading: {
      reducer: (state, action) => {
        state.waitingDataGridLoading = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
    setSelectedWaitingItem: {
      reducer: (state, action) => {
        state.selectedWaitingItem = action.payload;
      },
      prepare: (event) => ({ payload: event || '' }),
    },
    setWaitingSortModel: {
      reducer: (state, action) => {
        state.waitingSortModel = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
    setWaitingFilterModel: {
      reducer: (state, action) => {
        state.waitingFilterModel = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
  },
  extraReducers: {
    [getWaiting.fulfilled]: (state, action) => {
      waitingAdapter.setAll(state, action.payload);
      state.waitingDataGridLoading = false;
      state.waitingPageLoading = false;
    },
    [setWaiting.fulfilled]: (state, action) => {
      waitingAdapter.upsertMany(state, action.payload);
      state.waitingDataGridLoading = false;
    },
    [insertWaiting.fulfilled]: (state, action) => {
      waitingAdapter.addOne(state, action.payload);
      state.waitingDataGridLoading = false;
    },
    [updateWaiting.fulfilled]: (state, action) => {
      waitingAdapter.upsertOne(state, action.payload);
      state.waitingDataGridLoading = false;
    },
    [deleteWaiting.fulfilled]: (state, action) => {
      waitingAdapter.removeOne(state, action.payload);
      state.waitingDataGridLoading = false;
    },
    [sendApproveWaiting.fulfilled]: (state, action) => {
      state.waitingDataGridLoading = false;
    },
  },
});

export const {
  setWaitingSearchText,
  setSelectedWaitingItem,
  setWaitingDataGridLoading,
  setWaitingPageLoading,
  setWaitingSortModel,
  setWaitingFilterModel,
} = waitingSlice.actions;

export default waitingSlice.reducer;
