const locale = {
  AllContacts: 'Bütün Kullanıcılar',
  Contacts: 'Kullanıcılar',
  EditContact: 'Kullanıcı Düzenle',
  FrequentlyContacted: 'Yemeksepeti',
  NewContact: 'Yeni Kullanıcı',
  NoUsers: 'Kullanıcı yok!',
  Partners: 'Bayiler',
  Save: 'Kaydet',
  FirstName: 'Ad',
  LastName: 'Soyad',
  CompanyName: 'Şirket',
  Email: 'Email',
  Vkn: 'VKN',
  Tckn: 'TCKN',
  StoreCode: 'Depo Kodu',
  StoreName: 'Depo Adı',
  LegalName: 'Tüzel Ad',
  CurrentCode: 'Cari Kod',
  Role: 'Rol',
  TaxOffice: 'Vergi Merkezi',
  AccountCode: 'Hesap Kodu',
  AlreadyExistEmail: 'Bu email mevcut!',
  AlreadyExistStoreCode: 'Bu depo kodu mevcut!',
  Add: 'Ekle',
  SearchPlaceHolder: 'Ara',
  RowsPerPage: 'sayfa başına satır',
  Address: 'Adres',
  PhoneNumber: 'Telefon',
  COLUMN_NAME_ID: 'No',
  COLUMN_NAME_USERNAME: 'Kullanıcı Adı',
  COLUMN_NAME_FIRSTNAME: 'Ad',
  COLUMN_NAME_LASTNAME: 'Soyad',
  COLUMN_NAME_EMAIL: 'E-Posta',
  COLUMN_NAME_COMPANY_NAME: 'Şirket Adı',
  COLUMN_NAME_VKN: 'Vergi Kimlik No',
  COLUMN_NAME_TCKN: 'TC Kimlik No',
  COLUMN_NAME_STORE_CODE: 'Depo Kodu',
  COLUMN_NAME_STORE_NAME: 'Depo Adı',
  COLUMN_NAME_LEGAL_NAME: 'Tüzel Adı',
  COLUMN_NAME_TAX_OFFICE: 'Vergi Dairesi',
  COLUMN_NAME_ROLE: 'Yetkisi',
  COLUMN_NAME_PHONE_NUMBER: 'Telefon No',
  COLUMN_NAME_ADDRESS: 'Adres',
  TOOLTIP_NAME_EDIT: 'Düzenle',
  Delete: 'Sil',
  USERS_MESSAGE_DELETE__DIALOG: 'Seçilen kullanıcılar silinecek.Onaylıyor musunuz?',
  Agree: 'Onayla',
  Cancel: 'Reddet',
  USERS_NO_ROWS: 'Herhangi Kullanıcı Yok',
};

export default locale;
