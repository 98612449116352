export const onCreateRequestList = /* GraphQL */ `
  subscription OnCreateRequestList {
    onCreateRequestList {
      id
      viewId
    }
  }
`;
export const onUpdateRequestList = /* GraphQL */ `
  subscription OnUpdateRequestList {
    onUpdateRequestList {
      id
      viewId
    }
  }
`;
export const onDeleteRequestList = /* GraphQL */ `
  subscription OnDeleteRequestList {
    onDeleteRequestList {
      id
      viewId
    }
  }
`;

export const onUpdateRequest = /* GraphQL */ `
  subscription OnUpdateRequest {
    onUpdateRequest {
      id
      viewId
      status
      mailSendUserId
    }
  }
`;

export const onUpdateWaitingRequest = /* GraphQL */ `
  subscription OnUpdateRequest($owner: String) {
    onUpdateRequest(owner: $owner) {
      id
      viewId
      status
    }
  }
`;

export const onDeleteWaitingRequest = /* GraphQL */ `
  subscription OnDeleteRequest($owner: String) {
    onDeleteRequest(owner: $owner) {
      id
      viewId
      status
    }
  }
`;
