const locale = {
  AllContacts: 'All Users',
  Contacts: 'Users',
  EditContact: 'Edit User',
  FrequentlyContacted: 'Yemeksepeti',
  NewContact: 'New Contact',
  NoUsers: 'There are no users!',
  Partners: 'Partners',
  Save: 'Save',
  FirstName: 'Firstame',
  LastName: 'Lastname',
  CompanyName: 'Company',
  Email: 'Email',
  Vkn: 'VKN',
  Tckn: 'TCKN',
  StoreCode: 'Store Code',
  StoreName: 'Store Name',
  LegalName: 'Legal Name',
  CurrentCode: 'Currrent Code',
  Role: 'Role',
  TaxOffice: 'Tax Office',
  AccountCode: 'Account Code',
  AlreadyExistEmail: 'Email is already exist!',
  AlreadyExistStoreCode: 'Store Code is already exist!',
  Add: 'Add',
  SearchPlaceHolder: 'Search',
  RowsPerPage: 'row per page',
  Address: 'Address',
  PhoneNumber: 'Phone Number',
  COLUMN_NAME_ID: 'ID',
  COLUMN_NAME_USERNAME: 'User Name',
  COLUMN_NAME_FIRSTNAME: 'Firstname',
  COLUMN_NAME_LASTNAME: 'Lastname',
  COLUMN_NAME_EMAIL: 'E-Mail',
  COLUMN_NAME_COMPANY_NAME: 'Company Name',
  COLUMN_NAME_VKN: 'Tax ID',
  COLUMN_NAME_TCKN: 'Citizenship ID',
  COLUMN_NAME_STORE_CODE: 'Store Code',
  COLUMN_NAME_STORE_NAME: 'Store Name',
  COLUMN_NAME_LEGAL_NAME: 'Legal Name',
  COLUMN_NAME_TAX_OFFICE: 'Tax Office',
  COLUMN_NAME_ROLE: 'Role',
  COLUMN_NAME_PHONE_NUMBER: 'Phone',
  COLUMN_NAME_ADDRESS: 'Address',
  TOOLTIP_NAME_EDIT: 'Edit',
  Delete: 'Delete',
  USERS_MESSAGE_DELETE__DIALOG: 'The selected users will be deleted. Do you confirm?',
  Agree: 'Agree',
  Cancel: 'Cancel',
  USERS_NO_ROWS: 'No Users Data',
};

export default locale;
