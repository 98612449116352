import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { API, graphqlOperation } from 'aws-amplify';
import i18next from 'i18next';
import _ from 'lodash';

import {
  addNotificationRequests,
  deleteNotificationRequests,
  updateNotificationRequests,
} from '../../main/apps/requests/store/requestsSlice';
import * as subscriptions from '../graphql/subscriptions';
import en from '../i18n/en';
import tr from '../i18n/tr';
import { authRoles } from '../../auth';

i18next.addResourceBundle('en', 'RequestsNotification', en);
i18next.addResourceBundle('tr', 'RequestsNotification', tr);

const RequestsSubscriptions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('RequestsNotification');
  const authUser = useSelector(({ auth }) => auth.user);

  let isCurrentUserAuthenticated = false;
  let isFRUser = false;

  if (!_.isEmpty(authUser.role)) {
    isCurrentUserAuthenticated = _.has(authUser, 'uid');
    isFRUser = _.isEmpty(authUser.role.filter((e) => authRoles.authorized.includes(e)));
  }

  useEffect(() => {
    if (isCurrentUserAuthenticated && !isFRUser) {
      const addSubscription = API.graphql(
        graphqlOperation(subscriptions.onCreateRequestList)
      ).subscribe({
        next: ({ value }) => {
          const {
            data: {
              onCreateRequestList: { viewId },
            },
          } = value;

          dispatch(
            addNotificationRequests({
              message: t(`NOTIFICATION_MESSAGE_ADD_REQUEST_LIST`, { viewId }),
              options: { variant: 'success' },
            })
          );
        },
        error: (error) => {
          console.warn(error);
        },
      });

      return () => addSubscription.unsubscribe();
    }
  }, [dispatch, isCurrentUserAuthenticated, isFRUser, t]);

  useEffect(() => {
    if (isCurrentUserAuthenticated && !isFRUser) {
      const updateSubscription = API.graphql(
        graphqlOperation(subscriptions.onUpdateRequestList)
      ).subscribe({
        next: ({ value }) => {
          const {
            data: {
              onUpdateRequestList: { viewId },
            },
          } = value;

          dispatch(
            updateNotificationRequests({
              message: t(`NOTIFICATION_MESSAGE_UPDATE_REQUEST_LIST`, { viewId }),
              options: { variant: 'success' },
            })
          );
        },
        error: (error) => {
          console.warn(error);
        },
      });

      return () => updateSubscription.unsubscribe();
    }
  }, [dispatch, isCurrentUserAuthenticated, isFRUser, t]);

  useEffect(() => {
    if (isCurrentUserAuthenticated && !isFRUser) {
      const deleteSubscription = API.graphql(
        graphqlOperation(subscriptions.onDeleteRequestList)
      ).subscribe({
        next: ({ value }) => {
          const {
            data: {
              onDeleteRequestList: { viewId },
            },
          } = value;

          dispatch(
            deleteNotificationRequests({
              message: t(`NOTIFICATION_MESSAGE_DELETE_REQUEST_LIST`, { viewId }),
              options: { variant: 'success' },
            })
          );
        },
        error: (error) => {
          console.warn(error);
        },
      });

      return () => deleteSubscription.unsubscribe();
    }
  }, [dispatch, isCurrentUserAuthenticated, isFRUser, t]);
};

export default RequestsSubscriptions;
