const locale = {
  Products: 'Ürünler',
  UploadFile: 'Dosya Yükle',
  InvoiceSummary: 'Fatura Özeti',
  eInvoiceUpload: 'e-Fatura Yükleme',
  OrderDetails: 'Sipariş Detayları',
  NoPartnersDetail: 'İş Ortağı Ayrıntısı yok!',
  createdAt: 'Oluşturulma Tarihi',
  updatedAt: 'Güncellenme Tarihi',
  id: 'No',
  TOT: 'Toplam Fatura',
  TOV: 'KDV Dahil',
  PSB: 'Performans Destek Primi',
  RCO: 'Ciro Komisyon',
  PPO: 'Sipariş Başı Ödeme',
  TIP: 'Bahşiş',
  SSB: 'Startup Destek',
  STN: 'Depo Adı',
  TYP: 'Tür',
  STC: 'Depo Kodu',
  Status: 'Durumu',
  UPLOAD_TITLE: 'Yükle',
  UPLOAD_DISABLE_TITLE: 'Yükleme yapamazsınız',
  PENDING: 'Bekliyor',
  ERROR: 'Hata',
  VALID: 'Geçerli',
  COMPLETED: 'Tamamlandı',
  EMAIL_SENDED: 'E-Posta gönderildi',
  PARTNER_ERROR: 'Partner Hatası',
  PARTNER_PENDING: 'Bekleniyor',
  PARTNER_VALID: 'Geçerli',
  PARTNER_APPROVED: 'Onaya Gönderildi',
  CompletedRequests: 'Tamamlanmış Talepler',
  OpenRequests: 'Açık Talepler',
  Search: 'Ara',
  SendMail: 'Mail Gönder',
  SendToApproval: 'Onaya Gönder',
  GridTextLocal: 'TR',
  viewId: 'No',
  MESSAGE_DOWNLOAD_FILE: 'Dosya indiriliyor.',
  MESSAGE_DOWNLOAD_FILE_SUCCESS: 'Dosya başarıyla indirildi.',
  MESSAGE_UPLOAD_FILE_SUCCESS: 'Dosya başarıyla yüklendi.',
  MESSAGE_UPLOAD_FILE_ERROR: 'Yükleme sırasında hata ile karşılaşıldı.',
  MESSAGE_SEND_MAIL_SUCCESS: 'Mail başarıyla gönderildi.',
  MESSAGE_SEND_MAIL_ERROR: 'Mail gönderirken beklenmeyen bir hata ile karşılaşıldı.',
  BACK_REQUESTS_NAME: 'Talep Listelerine Geri Dön',
  BACK_COMPLETED_REQUESTS: 'Tamamlanmış Taleplere Geri Dön',
  COMPLETED_REQUESTS_DETAIL: 'Tamamlanmış Talep Detayı',
  REQUESTS_DETAIL_NAME: 'Talepler Detayı',
  CURRENT_LANGUAGE: 'trTR',
  momentLang: 'tr',
  NO_INVOICE_MESSAGE: 'Herhangi bir örnek fatura bulunmuyor',
  NO_INVOICE_LINK: 'Açık Talepler sayfasına geri dön',
  NO_REQUEST_MESSAGE: 'Herhangi bir tamamlanmış talep bulunmuyor',
  INVOICE_HEADER_PRODUCT: 'Mal Hizmet',
  INVOICE_HEADER_QUANTITY: 'Miktar',
  INVOICE_HEADER_EXPECTED_GOODS_AMOUNT: 'Beklenen Mal Hizmet Tutarı',
  INVOICE_HEADER_ENTERED_GOODS_AMOUNT: 'Girilen Mal Hizmet Tutarı',
  INVOICE_HEADER_STATUS: 'Durumu',
  INVOICE_CELL_QUANTITY: 'Adet',
  INVOICE_HEADER_EXPECTED_GOODS_TOTAL_AMOUNT: 'Mal Hizmet Toplam Tutarı',
  INVOICE_HEADER_EXPECTED_GOODS_IVAT_TOTAL_AMOUNT: 'Vergiler Dahil Toplam Tutar',
  INVOICE: 'ÖRNEK FATURA',
  requestListTableHeaderFileUploadPercentage: '{{percentage}} %',
  INVOICE_DATE: 'FATURA TARİHİ',
  INVOICE_TOTAL: 'FATURA TOPLAM',
  INVOICE_CLIENT: 'MÜŞTERİ',
  NO_ROWS: 'Bekleyen Talebiniz Yok',
  NO_ROWS_COMPLETED: 'Tamamlanmış Talebiniz Yok',
  formatDate: '{{date, dd/MM/yyyy}}',
  formatCurrency: '{{value, price, TRY}}',
  StoreCode: 'Depo Kodu',
  BACKGROUND: 'Örnektir',
  SYSTEM_ERROR_PDF2JSON_PARTNER_VKN_NOT_FOUND: "Yüklenen PDF'te Bayi VKN'si bulunamadı.",
  SYSTEM_ERROR_PDF2JSON_COMPANY_VKN_NOT_FOUND: "Yüklenen PDF'te Şirket VKN'si bulunamadı.",
  SYSTEM_ERROR_PDF2JSON_TOTAL_AMOUNT_NOT_FOUND: "Yüklenen PDF'te Toplam Tutar bulunamadı.",
  SYSTEM_ERROR_PDF2JSON_INCLUDE_VAT_NOT_FOUND:
    "Yüklenen PDF'te Vergiler Dahil Toplam Tutar bulunamadı.",
  SYSTEM_ERROR_PDF2JSON_PRODUCT_NAME_NOT_FOUND: "Yüklenen PDF'te Ürün İsmi bulunamadı.",
  SYSTEM_ERROR_PDF2JSON_PRODUCT_PRICE_NOT_FOUND: "Yüklenen PDF'te Ürün Fiyatı bulunamadı.",
  MESSAGE_SEND_APPROVE_DIALOG_TITLE: 'Onaya Gönderme',
  MESSAGE_SEND_APPROVE_DIALOG_CONTENT: 'Seçili talebi onaya göndermeyi onaylıyor musunuz?',
  MESSAGE_SEND_APPROVE_SUCCESS: 'Onaya gönderme işlemi başladı',
  MESSAGE_SEND_APPROVE_ERROR: 'Onaya gönderilemedi',
  SEND_TO_APPROVAL: 'Onaya Gönder',
  Cancel: 'Vazgeç',
  Agree: 'Onayla',
};

export default locale;
