import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { DataStore, Predicates, SortDirection } from 'aws-amplify';
import _ from 'lodash';

import { Invoice } from '../../../models';
import { authRoles } from '../../auth';
import { getWaitingDetail } from '../../main/apps/franchise/store/waitingDetailSlice';

const InvoiceObserve = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(({ auth }) => auth.user);

  let isCurrentUserAuthenticated = false;
  let isFRUser = false;
  let isOnlyAdmin = false;

  if (!_.isEmpty(authUser.role)) {
    isCurrentUserAuthenticated = _.has(authUser, 'uid');
    isFRUser = _.isEmpty(authUser.role.filter((e) => authRoles.authorized.includes(e)));
    isOnlyAdmin = !_.isEmpty(authUser.role.filter((e) => authRoles.admin.includes(e)));
  }

  useEffect(() => {
    if (isCurrentUserAuthenticated && isFRUser) {
      const subscription = DataStore.observeQuery(
        Invoice,
        (c) => c.and((c) => c.owner('eq', authUser.uid)),
        {
          sort: (s) => s.updatedAt(SortDirection.DESCENDING),
        }
      ).subscribe((snapshot) => {
        const { items, isSynced } = snapshot;
        if (isSynced && !_.isEmpty(items)) {
          dispatch(getWaitingDetail());
        }
      });

      return () => subscription.unsubscribe();
    }
  }, [authUser, dispatch, isCurrentUserAuthenticated, isFRUser]);

  useEffect(() => {
    if (isCurrentUserAuthenticated && isOnlyAdmin) {
      const subscription = DataStore.observeQuery(Invoice, Predicates.ALL, {
        sort: (s) => s.updatedAt(SortDirection.DESCENDING),
      }).subscribe((snapshot) => {
        const { items, isSynced } = snapshot;
        if (isSynced && !_.isEmpty(items)) {
          dispatch(getWaitingDetail());
        }
      });

      return () => subscription.unsubscribe();
    }
  }, [dispatch, isCurrentUserAuthenticated, isOnlyAdmin]);
};

export default InvoiceObserve;
