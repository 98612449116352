import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { API, Auth, DataStore } from 'aws-amplify';
import _ from 'lodash';

import { Request, RequestList } from '../../../../../models';
import { addNotification } from '../../../../fuse-layouts/shared-components/notificationPanel/store/dataSlice';
import NotificationModel from '../../../../fuse-layouts/shared-components/notificationPanel/model/NotificationModel';
import CognitoService from '../../../../services/cognitoService';
import { authRoles } from '../../../../auth';

export const getIsFRUser = async () => {
  const currentUser = await CognitoService.onAuthenticated();
  const roles = currentUser.signInUserSession.idToken.payload['cognito:groups'];
  const isFRUser = _.isEmpty(roles.filter((e) => authRoles.authorized.includes(e)));

  return isFRUser;
};

export const getRequests = createAsyncThunk(
  'requestsApp/requests/getRequests',
  async (val, { dispatch, getState }) => {
    dispatch(setDataGridLoading(true));
    const result = await DataStore.query(RequestList);

    return result;
  }
);
export const removeRequests = createAsyncThunk(
  'requestsApp/requests/removeRequests',
  async (params, { dispatch, getState }) => {
    dispatch(setDataGridLoading(true));
    const {
      requests: { selectionModel, entities },
    } = getState().requestsApp;
    const { t } = params;

    const disableSelectStatusList = [
      'PARTNER_VALID',
      'PARTNER_PENDING',
      'PARTNER_VALID',
      'PARTNER_ERROR',
      'PARTNER_APPROVED',
      'COMPLETED',
    ];
    for (const id of selectionModel) {
      const requestList = _.find(entities, { id });
      const requests = (await DataStore.query(Request)).filter(
        (c) => c.requestList.id === requestList.id && disableSelectStatusList.includes(c.status)
      );
      if (_.isEmpty(requests)) {
        DataStore.delete(requestList);
      } else {
        const { viewId } = requestList;
        dispatch(
          addNotification(
            NotificationModel({
              message: t(`MESSAGE_CANT_DELETE_REQUEST_LIST_ERROR`, { viewId }),
              options: { variant: 'error' },
            })
          )
        );
      }
    }
    // _.forEach(selectionModel, (selectionModelItem) => {
    //   const requestList = _.find(entities, { id: selectionModelItem });
    //   // DataStore.delete(requestList);
    // });
    return selectionModel;
  }
);
export const insertRequests = createAsyncThunk(
  'requestsApp/requests/addRequests',
  async (params, { dispatch, getState }) => {
    const { data } = params;

    const isFRUser = await getIsFRUser();
    if (isFRUser) {
      return null;
    }

    dispatch(setDataGridLoading(true));

    return data;
  }
);
export const updateRequests = createAsyncThunk(
  'requestsApp/requests/updateRequests',
  async (params, { dispatch, getState }) => {
    const { data } = params;

    const isFRUser = await getIsFRUser();
    if (isFRUser) {
      return null;
    }

    dispatch(setDataGridLoading(true));

    return data;
  }
);
export const deleteRequests = createAsyncThunk(
  'requestsApp/requests/deleteRequests',
  async (params, { dispatch, getState }) => {
    const { data } = params;

    const isFRUser = await getIsFRUser();
    if (isFRUser) {
      return null;
    }

    dispatch(setDataGridLoading(true));

    return data.id;
  }
);
export const addNotificationRequests = createAsyncThunk(
  'requestsApp/requests/addNotificationRequests',
  async (params, { dispatch, getState }) => {
    const isFRUser = await getIsFRUser();

    if (!isFRUser) {
      dispatch(addNotification(NotificationModel(params)));
    }

    return null;
  }
);
export const updateNotificationRequests = createAsyncThunk(
  'requestsApp/requests/updateNotificationRequests',
  async (params, { dispatch, getState }) => {
    const isFRUser = await getIsFRUser();

    if (!isFRUser) {
      dispatch(addNotification(NotificationModel(params)));
    }

    return null;
  }
);
export const deleteNotificationRequests = createAsyncThunk(
  'requestsApp/requests/deleteNotificationRequests',
  async (params, { dispatch, getState }) => {
    const isFRUser = await getIsFRUser();

    if (!isFRUser) {
      dispatch(addNotification(NotificationModel(params)));
    }

    return null;
  }
);

export const sendMailRequests = createAsyncThunk(
  'requestsApp/requests/sendMailRequest',
  async (params, { dispatch, getState }) => {
    dispatch(setDataGridLoading(true));

    const { requestIdValue, requestVersionValue, successMessage, errorMessage, lang, uid } = params;
    const requests = (await DataStore.query(Request)).filter(
      (c) => c.requestList.id === requestIdValue
    );
    const requestStatusList = _.map(requests, (i) => _.pick(i, 'id', 'status'));

    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const requestInfo = {
      body: {
        data: {
          language: lang,
          requestIdValue,
          requestVersionValue,
          owner: uid,
          requestStatusList,
          isRequestList: true,
        },
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await API.post('triggerapi', '/send-email', requestInfo);
    const isResultSuccess = !_.isEmpty(result) && result.isSuccess;

    dispatch(
      addNotification(
        NotificationModel({
          message: isResultSuccess ? successMessage : errorMessage,
          options: { variant: isResultSuccess ? 'success' : 'error' },
        })
      )
    );

    return result;
  }
);

const requestsAdapter = createEntityAdapter({});

export const { selectAll: selectRequests, selectById: selectRequestById } =
  requestsAdapter.getSelectors((state) => {
    return state.requestsApp.requests;
  });

const requestsSlice = createSlice({
  name: 'requestsApp/requests',
  initialState: requestsAdapter.getInitialState({
    searchText: '',
    dataGridLoading: true,
    pageLoading: true,
    pagination: {
      nextToken: null,
      nextNextToken: null,
      previousToken: [],
    },

    page: 1,
    pageList: [1],
    nextToken: null,
    currentPage: 1,
    limit: 10,
    sortModel: [
      {
        field: 'updatedAt',
        sort: 'desc',
      },
    ],
    filterModel: undefined,
    selectionModel: [],
  }),
  reducers: {
    setPageLoading: {
      reducer: (state, action) => {
        state.pageLoading = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
    setRequestsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setCurrentPage: {
      reducer: (state, action) => {
        state.currentPage = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
    setPage: {
      reducer: (state, action) => {
        state.page = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
    setPageList: {
      reducer: (state, action) => {
        state.pageList = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
    setPagination: {
      reducer: (state, action) => {
        state.pagination = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
    setDataGridLoading: {
      reducer: (state, action) => {
        state.dataGridLoading = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
    setSortModel: {
      reducer: (state, action) => {
        state.sortModel = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
    setFilterModel: {
      reducer: (state, action) => {
        state.filterModel = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
    setSelectionModel: {
      reducer: (state, action) => {
        state.selectionModel = action.payload;
      },
      prepare: (event) => ({ payload: event }),
    },
  },
  extraReducers: {
    [getRequests.fulfilled]: (state, action) => {
      requestsAdapter.setAll(state, action.payload);
      state.dataGridLoading = false;
      if (!_.isEmpty(action.payload)) {
        state.pageLoading = false;
      }
    },
    [removeRequests.fulfilled]: (state, action) => {
      requestsAdapter.removeMany(state, action.payload);
      state.dataGridLoading = false;
    },
    [insertRequests.fulfilled]: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        requestsAdapter.addOne(state, action.payload);
        state.dataGridLoading = false;
      }
    },
    [updateRequests.fulfilled]: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        requestsAdapter.upsertOne(state, action.payload);
        state.dataGridLoading = false;
      }
    },
    [deleteRequests.fulfilled]: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        requestsAdapter.removeOne(state, action.payload);
        state.dataGridLoading = false;
      }
    },
    [sendMailRequests.fulfilled]: (state, action) => {
      state.dataGridLoading = false;
    },
  },
});

export const {
  setPageLoading,
  setSortModel,
  setFilterModel,
  setSelectionModel,
  setDataGridLoading,
} = requestsSlice.actions;

export default requestsSlice.reducer;
