const locale = {
  LoginToYourAccount: 'Hesabınıza giriş yapın',
  Welcome: "Masraff'a",
  Login: 'Giriş Yap',
  ToThe: '',
  Email: 'Email',
  Password: 'Şifre',
  RememberMe: 'Beni Hatırla',
  Masraff: 'Hoşgeldiniz',
  AccountsPayableProcesses: 'Borç Hesapları Yönetim Süreçleri',
  CreateNewPassword: 'Yeni Şifre Belirleyin',
  Code: 'Kod',
  ConfirmPassword: 'Şifre (Tekrar)',
  ResendVerification: 'Doğrulama Kodunu Tekrar Gönder',
  SendVerificationCode: 'Doğrulama Kodu Gönder',
  GoBackToLogin: 'Login Ekranına Geri Dön',
  MailVerification: 'Email adresinizi doğrulayın',
  ResetMyPassword: 'Şifremi Resetle',
  VerificationSendedToMail: 'Doğrulama Kodu Mail Adresinize Gönderildi!',
  PleaseCheckToYourMailBox: 'Lütfen mail kutunuzu kontrol ediniz!',
  ForPasswordConfirmation:
    'Maildeki linke tıklayarak şifre değiştirme işleminizi tamamlayabilirsiniz.',
};

export default locale;
