const locale = {
  LoginToYourAccount: 'Login to your account',
  Welcome: 'Welcome',
  Login: 'Login',
  ToThe: 'to the',
  Email: 'Email',
  Password: 'Password',
  RememberMe: 'Remember Me',
  Masraff: 'Masraff',
  AccountsPayableProcesses: 'Accounts Payable Processes',
  ForgotPassword: 'Forgot Password',
  UserNotFoundException: 'User does not exist',
  NotAuthorizedException: 'Incorrect username or password',
};

export default locale;
