import { lazy } from 'react';
import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import tr from './i18n/tr';

// i18next.addResourceBundle('en', 'FranchiseListHeader', en);
// i18next.addResourceBundle('tr', 'FranchiseListHeader', tr);
//
// i18next.addResourceBundle('en', 'FranchiseListTable', en);
// i18next.addResourceBundle('tr', 'FranchiseListTable', tr);
//
// i18next.addResourceBundle('en', 'UploadTab', en);
// i18next.addResourceBundle('tr', 'UploadTab', tr);
//
// i18next.addResourceBundle('en', 'FranchiseDetail', en);
// i18next.addResourceBundle('tr', 'FranchiseDetail', tr);
//
i18next.addResourceBundle('en', 'Completed', en);
i18next.addResourceBundle('tr', 'Completed', tr);
//
i18next.addResourceBundle('en', 'CompletedTable', en);
i18next.addResourceBundle('tr', 'CompletedTable', tr);
//
i18next.addResourceBundle('en', 'CompletedHeader', en);
i18next.addResourceBundle('tr', 'CompletedHeader', tr);

i18next.addResourceBundle('en', 'CompletedDetailHeader', en);
i18next.addResourceBundle('tr', 'CompletedDetailHeader', tr);

i18next.addResourceBundle('en', 'CompletedDetailInvoice', en);
i18next.addResourceBundle('tr', 'CompletedDetailInvoice', tr);

i18next.addResourceBundle('en', 'Waiting', en);
i18next.addResourceBundle('tr', 'Waiting', tr);

i18next.addResourceBundle('en', 'WaitingTable', en);
i18next.addResourceBundle('tr', 'WaitingTable', tr);

i18next.addResourceBundle('en', 'WaitingHeader', en);
i18next.addResourceBundle('tr', 'WaitingHeader', tr);

i18next.addResourceBundle('en', 'WaitingDetail', en);
i18next.addResourceBundle('tr', 'WaitingDetail', tr);

i18next.addResourceBundle('en', 'WaitingDetailHeader', en);
i18next.addResourceBundle('tr', 'WaitingDetailHeader', tr);

i18next.addResourceBundle('en', 'WaitingDetailInvoice', en);
i18next.addResourceBundle('tr', 'WaitingDetailInvoice', tr);

i18next.addResourceBundle('en', 'NoRowsOverlay', en);
i18next.addResourceBundle('tr', 'NoRowsOverlay', tr);

const Waiting = lazy(() => import('./waiting/Waiting'));
const WaitingDetail = lazy(() => import('./waitingDetail/WaitingDetail'));
const CompletedApp = lazy(() => import('./completed/completed/Completed'));
const CompletedDetail = lazy(() => import('./completed/completedDetail/CompletedDetail'));

const FranchiseAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.partner,
  routes: [
    {
      path: 'completed',
      element: <CompletedApp />,
    },
    {
      path: 'completed/:id',
      element: <CompletedDetail />,
    },
    {
      path: 'waiting',
      element: <Waiting />,
    },
    {
      path: 'waiting/:id',
      element: <WaitingDetail />,
    },
  ],
};

export default FranchiseAppConfig;
