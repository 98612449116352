import { lazy } from 'react';
import i18next from 'i18next';
import { authRoles } from 'app/auth';
import en from './i18n/en';
import tr from './i18n/tr';

i18next.addResourceBundle('en', 'RequestsHeader', en);
i18next.addResourceBundle('tr', 'RequestsHeader', tr);

i18next.addResourceBundle('en', 'RequestsTable', en);
i18next.addResourceBundle('tr', 'RequestsTable', tr);

i18next.addResourceBundle('en', 'RequestsTableHead', en);
i18next.addResourceBundle('tr', 'RequestsTableHead', tr);

i18next.addResourceBundle('en', 'RequestHeader', en);
i18next.addResourceBundle('tr', 'RequestHeader', tr);

i18next.addResourceBundle('en', 'RequestTable', en);
i18next.addResourceBundle('tr', 'RequestTable', tr);

i18next.addResourceBundle('en', 'Request', en);
i18next.addResourceBundle('tr', 'Request', tr);

i18next.addResourceBundle('en', 'Requests', en);
i18next.addResourceBundle('tr', 'Requests', tr);

i18next.addResourceBundle('en', 'RequestsNoRowsOverlay', en);
i18next.addResourceBundle('tr', 'RequestsNoRowsOverlay', tr);

i18next.addResourceBundle('en', 'RequestsTableColumn', en);
i18next.addResourceBundle('tr', 'RequestsTableColumn', tr);

i18next.addResourceBundle('en', 'RequestTableColumn', en);
i18next.addResourceBundle('tr', 'RequestTableColumn', tr);

const Requests = lazy(() => import('./requests/Requests'));
const RequestDetail = lazy(() => import('./request/Request'));

const RequestsAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.authorized,
  routes: [
    {
      path: 'requests',
      element: <Requests />,
    },
    {
      path: 'requests/:requestListId',
      element: <RequestDetail />,
    },
  ],
};

export default RequestsAppConfig;
