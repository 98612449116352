import { lazy } from 'react';
import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from '../i18n/en';
import tr from '../i18n/tr';

const Error404Page = lazy(() => import('./Error404Page'));

i18next.addResourceBundle('en', 'Error404Page', en);
i18next.addResourceBundle('tr', 'Error404Page', tr);

const Error404PageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.all,
  routes: [
    {
      path: 'pages/errors/error-404',
      element: <Error404Page />,
    },
  ],
};

export default Error404PageConfig;
