import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import i18next from 'i18next';
import { authRoles } from 'app/auth';
import en from './i18n/en';
import tr from './i18n/tr';

i18next.addResourceBundle('en', 'ContactsHeader', en);
i18next.addResourceBundle('tr', 'ContactsHeader', tr);

i18next.addResourceBundle('en', 'ContactsSidebarContent', en);
i18next.addResourceBundle('tr', 'ContactsSidebarContent', tr);

i18next.addResourceBundle('en', 'ContactsList', en);
i18next.addResourceBundle('tr', 'ContactsList', tr);

i18next.addResourceBundle('en', 'ContactDialog', en);
i18next.addResourceBundle('tr', 'ContactDialog', tr);

i18next.addResourceBundle('en', 'ContactsTable', en);
i18next.addResourceBundle('tr', 'ContactsTable', tr);

i18next.addResourceBundle('en', 'ContactsTableColumn', en);
i18next.addResourceBundle('tr', 'ContactsTableColumn', tr);

const ContactsApp = lazy(() => import('./ContactsApp'));

const ContactsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.authorized,
  routes: [
    {
      path: 'users/:id',
      element: <ContactsApp />,
    },
    {
      path: 'users',
      element: <Navigate to="/users/all" />,
    },
  ],
};

export default ContactsAppConfig;
