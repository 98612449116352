import { lazy } from 'react';
import i18next from 'i18next';
import { authRoles } from 'app/auth';
import en from './i18n/en';
import tr from './i18n/tr';

i18next.addResourceBundle('en', 'Tag', en);
i18next.addResourceBundle('tr', 'Tag', tr);

const Tag = lazy(() => import('./Tag'));

const TagAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.authorized,
  routes: [
    {
      path: 'products/:productId',
      element: <Tag />,
    },
  ],
};

export default TagAppConfig;
