import { lazy } from 'react';
import {authRoles} from "../../../../auth";

const ResetPassword2Page = lazy(() => import('./ResetPassword2Page'));

const ResetPassword2PageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'reset-password',
      element: <ResetPassword2Page />,
    },
  ],
};

export default ResetPassword2PageConfig;