// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const RequestStatus = {
  "PENDING": "PENDING",
  "VALID": "VALID",
  "ERROR": "ERROR",
  "EMAIL_SENDED": "EMAIL_SENDED",
  "EMAIL_ERROR": "EMAIL_ERROR",
  "PARTNER_PENDING": "PARTNER_PENDING",
  "PARTNER_VALID": "PARTNER_VALID",
  "PARTNER_ERROR": "PARTNER_ERROR",
  "PARTNER_APPROVED": "PARTNER_APPROVED",
  "COMPLETED": "COMPLETED"
};

const RequestsSourceStatus = {
  "UPLOAD": "UPLOAD",
  "MANUEL": "MANUEL"
};

const ErrorStatus = {
  "TYPE_ERROR": "TYPE_ERROR",
  "NULL_ERROR": "NULL_ERROR",
  "BUSINESS_ERROR": "BUSINESS_ERROR",
  "PRODUCT_NOT_FOUND": "PRODUCT_NOT_FOUND"
};

const ProductType = {
  "STRING": "string",
  "NUMBER": "number",
  "DATE": "date",
  "DATE_TIME": "dateTime",
  "BOOLEAN": "boolean",
  "MONEY": "money",
  "SINGLE_SELECT": "singleSelect",
  "ACTIONS": "actions"
};

const { RequestList, Request, Invoice, Product, Tag, User, SystemError, Error, RequestColumns, RequestColumnsStyle, RequestsItem } = initSchema(schema);

export {
  RequestList,
  Request,
  Invoice,
  Product,
  Tag,
  User,
  SystemError,
  RequestStatus,
  RequestsSourceStatus,
  ErrorStatus,
  ProductType,
  Error,
  RequestColumns,
  RequestColumnsStyle,
  RequestsItem
};