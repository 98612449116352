import QuickPanel from 'app/fuse-layouts/shared-components/quickPanel/QuickPanel';
import { memo } from 'react';
import NotificationPanel from "../../shared-components/notificationPanel/NotificationPanel";

function RightSideLayout1(props) {
  return (
    <>
      <QuickPanel />

      <NotificationPanel />

    </>
  );
}

export default memo(RightSideLayout1);
