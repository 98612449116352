import { useEffect } from 'react';
import { DataStore } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { Tag } from '../../../models';
import { authRoles } from '../../auth';

const TagObserve = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(({ auth }) => auth.user);

  let isCurrentUserAuthenticated = false;
  let isFRUser = false;

  if (!_.isEmpty(authUser.role)) {
    isCurrentUserAuthenticated = _.has(authUser, 'uid');
    isFRUser = _.isEmpty(authUser.role.filter((e) => authRoles.authorized.includes(e)));
  }

  useEffect(() => {
    if (isCurrentUserAuthenticated && !isFRUser) {
      const subscription = DataStore.observe(Tag).subscribe((msg) => {
        // TODO: TagSlice a eklenip cagirilmasi gerekiyor (Kaan)
        // const handler = {
        //   insertRequests,
        //   updateRequests,
        //   deleteRequests,
        // };
        //
        // dispatch(
        //   handler[`${_.toLower(msg.opType)}Requests`]({
        //     data: msg.element,
        //   })
        // );
      });

      return () => subscription.unsubscribe();
    }
  }, [dispatch, isCurrentUserAuthenticated, isFRUser]);
};

export default TagObserve;
