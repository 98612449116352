const locale = {
  Products: 'Products',
  UploadFile: 'Upload File',
  InvoiceSummary: 'Invoice Summary',
  eInvoiceUpload: 'e-Invoice Upload',
  OrderDetails: 'Order Details',
  NoPartnersDetail: 'There are no Partners Detail!',
  createdAt: 'Create Date',
  updatedAt: 'Update Date',
  id: 'Id',
  TOT: 'Total Amount',
  TOV: 'VAT Included',
  PSB: 'Performance Support Bonus',
  RCO: 'Revenue Commission',
  PPO: 'Payment Per Order',
  TIP: 'Tip',
  SSB: 'Startup Support Bonus',
  STN: 'Store Name',
  TYP: 'Type',
  STC: 'Store Code',
  Status: 'Status',
  UPLOAD_TITLE: 'Upload',
  UPLOAD_DISABLE_TITLE: "You can't upload",
  PENDING: 'Pending',
  ERROR: 'Error',
  VALID: 'Valid',
  COMPLETED: 'Completed',
  EMAIL_SENDED: 'Mail Sended',
  PARTNER_ERROR: 'Partner Error',
  PARTNER_PENDING: 'Partner Pending',
  PARTNER_VALID: 'Partner Valid',
  PARTNER_APPROVED: 'Sent for Approval',
  CompletedRequests: 'Completed Requests',
  OpenRequests: 'Open Requests',
  Search: 'Search',
  SendMail: 'Send Mail',
  SendToApproval: 'Send to Approval',
  GridTextLocal: 'EN',
  viewId: 'Id',
  MESSAGE_DOWNLOAD_FILE: 'Downloading File.',
  MESSAGE_DOWNLOAD_FILE_SUCCESS: 'File downloaded successfully',
  MESSAGE_UPLOAD_FILE_SUCCESS: 'File uploaded successfully',
  MESSAGE_UPLOAD_FILE_ERROR: 'Unexpected error while uploading',
  MESSAGE_SEND_MAIL_SUCCESS: 'Mail sent successfully.',
  MESSAGE_SEND_MAIL_ERROR: 'Unexpected error while sending mail',
  CURRENT_LANGUAGE: 'enUS',
  momentLang: 'en',
  NO_INVOICE_MESSAGE: 'There is no such invoice',
  NO_INVOICE_LINK: 'Go to Waiting Page',
  NO_REQUEST_MESSAGE: 'There are no completed requests',
  INVOICE_HEADER_PRODUCT: 'Product',
  INVOICE_HEADER_QUANTITY: 'Quantity',
  INVOICE_HEADER_EXPECTED_GOODS_AMOUNT: 'Expected Goods Amount',
  INVOICE_HEADER_ENTERED_GOODS_AMOUNT: 'Entered Goods Amount',
  INVOICE_HEADER_STATUS: 'Status',
  INVOICE_CELL_QUANTITY: '',
  BACK_REQUESTS_NAME: 'Back Request List',
  BACK_COMPLETED_REQUESTS: 'Back to Completed Requests',
  COMPLETED_REQUESTS_DETAIL: 'Completed Request Detail',
  REQUESTS_DETAIL_NAME: 'Requests Detail',
  INVOICE_HEADER_EXPECTED_GOODS_TOTAL_AMOUNT: 'Total Amount',
  INVOICE_HEADER_EXPECTED_GOODS_IVAT_TOTAL_AMOUNT: 'Include VAT Total Amount',
  INVOICE: 'EXAMPLE INVOICE',
  requestListTableHeaderFileUploadPercentage: '{{percentage}} %',
  INVOICE_DATE: 'INVOICE DATE',
  INVOICE_TOTAL: 'TOTAL DUE',
  INVOICE_CLIENT: 'CLIENT',
  // MESSAGE_DELETE_REQUEST_LIST: 'Request list with ID: {{id}} has been deleted.',
  // MESSAGE_UPDATE_REQUEST_LIST: 'Request list with ID: {{id}} has been updated.',
  // MESSAGE_ADD_REQUEST_LIST: 'Request list with ID: {{id}} has been created.',
  NO_ROWS: 'No Waiting Data',
  NO_ROWS_COMPLETED: "You don't have any Completed Request",
  formatDate: '{{date, dd/MM/yyyy}}',
  formatCurrency: '{{value, price, USD}}',
  StoreCode: 'Store Code',
  BACKGROUND: 'Example',
  SYSTEM_ERROR_PDF2JSON_PARTNER_VKN_NOT_FOUND: 'Partner VKN not found in given PDF',
  SYSTEM_ERROR_PDF2JSON_COMPANY_VKN_NOT_FOUND: 'Company VKN not found in given PDF',
  SYSTEM_ERROR_PDF2JSON_TOTAL_AMOUNT_NOT_FOUND: 'Total Amount not found in given PDF',
  SYSTEM_ERROR_PDF2JSON_INCLUDE_VAT_NOT_FOUND: 'Total Amount Include VAT not found in given PDF',
  SYSTEM_ERROR_PDF2JSON_PRODUCT_NAME_NOT_FOUND: 'Product Name not found in given PDF',
  SYSTEM_ERROR_PDF2JSON_PRODUCT_PRICE_NOT_FOUND: 'Product Price not found in given PDF',
  MESSAGE_SEND_APPROVE_DIALOG_TITLE: 'Send Approval',
  MESSAGE_SEND_APPROVE_DIALOG_CONTENT:
    'Do you approve to submit the selected request for approval?',
  MESSAGE_SEND_APPROVE_SUCCESS: 'Approve process started',
  MESSAGE_SEND_APPROVE_ERROR: 'Failed to submit for approval',
  SEND_TO_APPROVAL: 'Send to Approval',
  Cancel: 'Cancel',
  Agree: 'Agree',
};

export default locale;
