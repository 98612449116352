import RequestObserve from './request/observe';
import RequestsSubscriptions from './requests/subscriptions';
import RequestSubscriptions from './request/subscriptions';
import WaitingObserve from './waiting/observe';
import WaitingSubscriptions from './waiting/subscriptions';
import InvoiceObserve from './invoice/observe';
import UserObserve from './user/observe';
import ProductObserve from './product/observe';
import TagObserve from './tag/observe';
import RequestsObserve from './requests/observe';

const AllSubscriptions = () => {
  RequestsObserve();
  RequestsSubscriptions();

  RequestObserve();
  RequestSubscriptions();

  WaitingObserve();
  WaitingSubscriptions();

  InvoiceObserve();

  UserObserve();

  ProductObserve();

  TagObserve();
};

export default AllSubscriptions;
