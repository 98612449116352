import _ from 'lodash';

const PrepareStatus = (requestsList) => {
  let resultStatus = null;
  const someStatusList = ['ERROR', 'PENDING', 'PARTNER_PENDING'];
  const everyStatusList = ['EMAIL_SENDED', 'COMPLETED', 'VALID', 'PARTNER_VALID'];

  if (!_.isEmpty(requestsList)) {
    _.forEach(someStatusList, (someStatusListItem) => {
      const hasSomeStatusList = _.some(requestsList, { status: someStatusListItem });
      if (hasSomeStatusList) {
        resultStatus = someStatusListItem;
        return false;
      }
    });

    if (!_.includes(someStatusList, resultStatus)) {
      _.forEach(everyStatusList, (everyStatusListItem) => {
        const hasEveryStatusList = _.every(requestsList, { status: everyStatusListItem });
        if (hasEveryStatusList) {
          resultStatus = everyStatusListItem;
          return false;
        }
        resultStatus = 'PENDING';
      });
    }
  } else {
    resultStatus = 'PENDING';
  }

  return resultStatus;
};

export default PrepareStatus;
