const locale = {
  NOTIFICATION_MESSAGE_ADD_REQUEST_LIST: '{{viewId}} request list added',
  NOTIFICATION_MESSAGE_UPDATE_REQUEST_LIST: '{{viewId}} request list updated',
  NOTIFICATION_MESSAGE_DELETE_REQUEST_LIST: '{{viewId}} request list deleted',
  NOTIFICATION_MESSAGE_DELETE_REQUEST_WAITING: '{{viewId}} request deleted',
  PARTNER_PENDING_NOTIFICATION_MESSAGE_UPDATE_WAITING: 'New request',
  PARTNER_VALID_NOTIFICATION_MESSAGE_UPDATE_WAITING: 'Invoice verified',
  PARTNER_ERROR_NOTIFICATION_MESSAGE_UPDATE_WAITING: 'Incorrect invoice',
  PARTNER_APPROVED_NOTIFICATION_MESSAGE_UPDATE_WAITING: 'Invoice sent for approval',
};

export default locale;
