import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { DataStore, SortDirection } from 'aws-amplify';
import _ from 'lodash';

import { Request, RequestStatus } from '../../../models';
import { authRoles } from '../../auth';
import { setWaiting } from '../../main/apps/franchise/store/waitingSlice';

const WaitingObserve = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(({ auth }) => auth.user);

  let isCurrentUserAuthenticated = false;
  let isFRUser = false;

  if (!_.isEmpty(authUser.role)) {
    isCurrentUserAuthenticated = _.has(authUser, 'uid');
    isFRUser = _.isEmpty(authUser.role.filter((e) => authRoles.authorized.includes(e)));
  }

  useEffect(() => {
    if (isCurrentUserAuthenticated && isFRUser) {
      const subscription = DataStore.observeQuery(
        Request,
        (c) =>
          c
            .and((c) => c.owner('eq', authUser.uid))
            .or((c) =>
              c
                .status('eq', RequestStatus.PARTNER_PENDING)
                .status('eq', RequestStatus.PARTNER_ERROR)
                .status('eq', RequestStatus.PARTNER_VALID)
                .status('eq', RequestStatus.PARTNER_APPROVED)
            ),
        {
          sort: (s) => s.updatedAt(SortDirection.DESCENDING),
        }
      ).subscribe((snapshot) => {
        const { items, isSynced } = snapshot;
        if (isSynced && !_.isEmpty(items)) {
          dispatch(setWaiting({ items }));
        }
      });

      return () => subscription.unsubscribe();
    }
  }, [dispatch, isCurrentUserAuthenticated, isFRUser]);
};

export default WaitingObserve;
