import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { API } from 'aws-amplify';
import i18next from 'i18next';
import _ from 'lodash';

import * as subscriptions from '../graphql/subscriptions';
import en from '../i18n/en';
import tr from '../i18n/tr';
import {
  updateNotificationWaiting,
  deleteNotificationWaiting,
} from '../../main/apps/franchise/store/waitingSlice';
import { authRoles } from '../../auth';

i18next.addResourceBundle('en', 'WaitingNotification', en);
i18next.addResourceBundle('tr', 'WaitingNotification', tr);

const WaitingSubscriptions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('WaitingNotification');
  const authUser = useSelector(({ auth }) => auth.user);
  const isVisibleNotificationFRUser = (status) => {
    return _.includes(
      ['PARTNER_PENDING', 'PARTNER_VALID', 'PARTNER_ERROR', 'PARTNER_APPROVED'],
      status
    );
  };

  let isCurrentUserAuthenticated = false;
  let isFRUser = false;

  if (!_.isEmpty(authUser.role)) {
    isCurrentUserAuthenticated = _.has(authUser, 'uid');
    isFRUser = _.isEmpty(authUser.role.filter((e) => authRoles.authorized.includes(e)));
  }

  useEffect(() => {
    if (isCurrentUserAuthenticated && isFRUser) {
      const updateSubscription = API.graphql({
        query: subscriptions.onUpdateWaitingRequest,
        variables: { owner: authUser.uid },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      }).subscribe({
        next: ({ value }) => {
          const {
            data: {
              onUpdateRequest: { viewId, status },
            },
          } = value;

          const isNotificationVisible = isVisibleNotificationFRUser(status);
          if (isNotificationVisible) {
            dispatch(
              updateNotificationWaiting({
                message: t(`${status}_NOTIFICATION_MESSAGE_UPDATE_WAITING`, { viewId }),
                options: { variant: 'success' },
              })
            );
          }
        },
        error: (error) => {
          console.warn(error);
        },
      });

      return () => updateSubscription.unsubscribe();
    }
  }, [authUser.uid, dispatch, isCurrentUserAuthenticated, isFRUser, t]);

  useEffect(() => {
    if (isCurrentUserAuthenticated && isFRUser) {
      const deleteSubscription = API.graphql({
        query: subscriptions.onDeleteWaitingRequest,
        variables: { owner: authUser.uid },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      }).subscribe({
        next: ({ value }) => {
          const {
            data: {
              onDeleteRequest: { viewId, status },
            },
          } = value;

          const isNotificationVisible = isVisibleNotificationFRUser(status);
          if (isNotificationVisible) {
            dispatch(
              deleteNotificationWaiting({
                message: t(`NOTIFICATION_MESSAGE_DELETE_REQUEST_WAITING`, { viewId }),
                options: { variant: 'success' },
              })
            );
          }
        },
        error: (error) => {
          console.warn(error);
        },
      });

      return () => deleteSubscription.unsubscribe();
    }
  }, [authUser, dispatch, isCurrentUserAuthenticated, isFRUser, t]);
};

export default WaitingSubscriptions;
