const locale = {
  AccountCode: 'Cari Kod',
  Active: 'AKTİF',
  Add: 'Ekle',
  Code: 'Kod',
  Content: 'İçerik',
  CreateDate: 'Oluşturma Tarihi',
  Description: 'Açıklama',
  EditProduct: 'Ürün Düzenle',
  ERROR: 'HATA',
  IsActive: 'Aktif',
  IsEditable: 'Değiştirilebilir',
  IsHideable: 'Gizlenebilir',
  IsNullable: 'Boş geçilebilir',
  IsSystemProduct: 'Sistem Ürünü',
  Name: 'Ad',
  NewProduct: 'Yeni Ürün',
  NewTag: 'Yeni Etiket',
  MESSAGE_SAME_CONTENT_WARNING: 'Aynı koşulu sağlayan baska bir metin bulunmaktadır.',
  MESSAGE_DELETE_TAG_DIALOG: 'Aşağıdaki etiketler silinecek, onaylıyor musunuz?',
  NO_TAG_MESSAGE: 'Herhangi bir etiket bulunmuyor!',
  NO_TAG_LINK: 'Ürünler Sayfasına Geri Dön',
  NoProducts: 'Ürün yok!',
  NoRequests: 'Talep yok!',
  OK: 'TAMAM',
  Passive: 'PASİF',
  Products: 'Ürünler',
  Requests: 'Talepler',
  Save: 'Kaydet',
  SENDMAIL: 'MAİL GÖNDER',
  SERVER_STATUS_CONNECTED: 'Çevrimiçi',
  SERVER_STATUS_DISCONNECTED: 'Çevrimdışı',
  Search: 'Ara',
  Status: 'Durumu',
  Text: 'Metin',
  Type: 'Tipi',
  UpdateDate: 'Güncellenme Tarihi',
  Upload: 'Yükle',
  UPLOAD: 'YÜKLE',
  ValueOptions: 'Değer Opsiyonları',
  ValueOptionsPlaceholder: 'Birden fazla değer opsiyonu seçebilirsiniz',
  ViewId: 'No',
  BackToProducts: 'Ürünlere geri dön',
  Tags: 'Etiketler',
  NoTags: 'Etiket yok!',
  HasInvoiceLine: 'Fatura Satırı',
  CURRENT_LANGUAGE: 'trTR',
  formatDate: '{{date, dd/MM/yyyy}}',
};

export default locale;
